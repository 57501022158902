<template>
  <div class="animated fadeIn vendor-create">
  
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <h4 class="d-block mb-4"><i class="nav-icon icon-plus"></i> Create Invoice <i class="ml-2 fa fa fa-question-circle" v-tooltip="'<p>1. Select a company</p><p>2. Select a project</p><p>3. Select a well</p><p>4. Add your service line items</p><p>5. Assign AFE / PO and codes to each line items (This is optional for vendors)</p>'"></i></h4>
            <!-- Invoice Details -->
            <b-row class="mb-4">
              <b-col class="col-12 mb-3 col-md-12 col-lg-4">
                <h4 class="mb-3">Billing From:</h4>
                <hr>
                <div v-if="vendorAvatar" class="mt-3 mb-3">
                  <img class="companylogo" v-bind:src="vendorAvatar" />
                </div>
                <h5>{{vendorDetails.company_name}}</h5>
                <p>{{vendorDetails.fname}} {{vendorDetails.lname}}</span></p>
                <ul class="fa-ul invoice-detail-lists">
                  <li><span class="fa-li"><i class="fa fa-building"></i></span>{{vendorDetails.addressline1}}</li>
                  <li v-if="vendorDetails.addressline2"><span class="fa-li"><i class="fa fa-building"></i></span>{{vendorDetails.addressline2}}</li>
                  <li><span class="fa-li"><i class="fa fa-building"></i></span>{{vendorDetails.city}}, {{vendorDetails.province}}</li>
                  <li class="text-uppercase"><span class="fa-li"><i class="fa fa-building"></i></span>{{vendorDetails.postalcode}}</li>
                  <li><span class="fa-li"><i class="fa fa-phone fa-flip-horizontal"></i></span>{{vendorDetails.phone_num}}</li>
                  <li v-if="vendorDetails.fax_num"><span class="fa-li"><i class="fa fa-fax"></i></span>{{vendorDetails.fax_num}}</li>
                  <hr>
                  <li><span class="fa-li"><i class="fa fa-university"></i></span>GST#: {{vendorDetails.gst}}</li>
                  <li><span class="fa-li"><i class="fa fa-university"></i></span>RCN#: {{vendorDetails.rcn}}</li>
                  <li><span class="fa-li"><i class="fa fa-university"></i></span>Bank Account#: {{vendorDetails.bankaccount}}</li>
                </ul>
              </b-col>
              <b-col class="col-12 col-md-6 col-lg-4">
                <h4 class="mb-3">Bill To:</h4>
                <hr>
                <div class="mt-3 mb-4" v-if="avatar">
                  <img class="companylogo" v-bind:src="avatar" />
                </div>
                <div class="mb-4">
                  <select :disabled="serviceData.length > 0" class="rt-select w-75" v-model="companyId" @change="get_projects">
                    <option value="0" disabled>Select Company</option>
                    <option v-for="company in companyData" :value="company.companyid">{{company.cn}}</option>
                  </select>
                </div>
                <ul class="fa-ul invoice-detail-lists" v-if="companyId && !loadingCompany">
                  <li><span class="fa-li"><i class="fa fa-building"></i></span>{{companyInfo.addressline1}}</li>
                  <li v-if="companyInfo.addressline2"><span class="fa-li"><i class="fa fa-building"></i></span>{{companyInfo.addressline2}}</li>
                  <li><span class="fa-li"><i class="fa fa-building"></i></span>{{companyInfo.city}}, {{companyInfo.province}}</li>
                  <li><span class="fa-li"><i class="fa fa-building"></i></span>{{companyInfo.postalcode}}</li>
                </ul>
                <label v-if="projectid && presetData.length > 0" for="">Import Preset</label>
                <div class="mt-3" v-if="presetData.length > 0">
                  <select :disabled="serviceData.length > 0" v-if="projectid" class="rt-select w-75" v-model="preset" @input="showImportModal">
                    <option value="0" disabled>Select Preset</option>
                    <option v-for="preset in presetData" :value="preset">{{preset.it_name}}</option>
                  </select>
                </div>
                <b-modal ref="import-modal" class="import-modal" hide-footer :title="'Import Preset: ' + preset.it_name">
                  <b-form-checkbox
                    v-model="changeDates"
                    value="1"
                    unchecked-value="0"
                  >
                    Change All Dates During Import
                  </b-form-checkbox>
                  <div class="row my-3" v-if="changeDates == 1">
                    <div class="col-md-12">
                      <h5>Start - End</h5>
                      <v-date-picker
                        class="vc-calendar"
                        :formats='formats'
                        mode='range'
                        v-model='preset_date'
                        show-caps>
                      </v-date-picker>
                    </div>
                  </div>
<!-- :disabled="disableImport" -->
                  <b-button class="mt-4" @click="importPreset" variant="success" block>Import</b-button>
                </b-modal>
                <b-modal ref="service-modal" class="service-modal" centered size="lg" hide-footer :title="'Select Your Service From The Following'" id="services-modal">
                  <v-client-table class="table vendor-service-select-table" :columns="columns" :data="vendorServices" :options="options" id="dataTable">
                    <div slot="name" slot-scope="props">
                      <span>
                        <input class="mr-2" v-if="!vendoreService_toggler.includes(props.row.serviceid)" type="checkbox" @click="selectService(props.row)">
                      <i v-else @click="deselectService(props.row)" class="fa fa-trash mr-2" style="color:#F64947"></i>
                      {{props.row.name}}
                      </span>
                    </div>

                    <div slot="type" slot-scope="props">
                      <span>{{props.row.type}}</span>
                    </div>
                      
                       <div slot="uom1" slot-scope="props">
                      <span>{{props.row.uom1 !== null ? '$'+props.row.pu1+'/' +props.row.uom1: '-'}}</span>
                    </div>

                         <div slot="uom2" slot-scope="props">
                      <span>{{props.row.uom2 !== null ? '$'+props.row.pu2 +'/' +props.row.uom2 : '-'}}</span>
                    </div>

                         <div slot="uom3" slot-scope="props">
                      <span>{{props.row.uom3 !== null ? '$'+props.row.pu3+'/' +props.row.uom3: '-'}}</span>
                    </div>

                  </v-client-table>
                </b-modal>
              </b-col>
              <b-col class="col-12 col-md-6 col-lg-4">
                <h4 class="mb-3">Details:</h4>
                <hr>
                <h5 class="mb-3">Date: {{ new Date() | moment("MM/DD/YYYY") }}</h5>
                <h6 class="font-weight-normal"></h6>
                <div class="my-2" v-if="companyId">
                  <label for="vwref_data"><b>Cost Centre/Well</b></label>
                  <v-select
                    :options="uwiData"
                    class="mt-2"
                    :filterable="true"
                    :label="'uwi'"
                    v-model="vwref"
                    @change="selectWell()"
                  >
                    <template #list-footer>
                      <li ref="load" class="loader">
                        Loading more options...
                      </li>
                    </template>
                    <template v-slot:option="option">
                      <span v-if="option.uwi_3 !== null" >{{ option.uwi_3 }}-</span>
                      <span v-if="option.uwi_2 !== null">{{ option.uwi_2 }}-</span>
                      <span v-if="option.uwi !== null">{{ option.uwi }}</span>
                    </template>
                    <template #selected-option="{ uwi, uwi_2, uwi_3, option, deselect, multiple, disabled }">
                      <span v-if="uwi_3 !== null">{{ uwi_3 }}-</span>
                      <span v-if="uwi_2 !== null">{{ uwi_2 }}-</span>
                      <span v-if="uwi !== null">{{ uwi }}</span>
                    </template>
                  </v-select>
                </div>

                <div class="mt-2" v-if="projectid">
                  <label for=""><b>(Site/Area/Project):</b> {{projectname}}</label><br />
                  <span v-if="vendorServices.length == 0" style="color: red;">You are not invited to this project.</span>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="form-group col-md-6 col-lg-4">
                <label for="field-ticket-number" class="font-weight-bold">Field Ticket Number</label>
                <input class="form-control" type="text" placeholder="Enter Field Ticket Number"
                       v-model="fieldTicketNumber" id="field-ticket-number">
              </b-col>

              <b-col class="form-group col-md-6 col-lg-4">
                <label for="vendor-invoice-number" class="font-weight-bold">Vendor Invoice Number</label>
                <input class="form-control" type="text" placeholder="Enter Vendor Invoice Number"
                       v-model="vendorInvoiceNumber" id="vendor-invoice-number">
              </b-col>

              <b-col class="form-group col-md-6 col-lg-4">
                <label for="service-order-number" class="font-weight-bold">Service Order Number</label>
                <input class="form-control" type="text" placeholder="Enter Service Order Number"
                       v-model="serviceOrderNumber" id="service-order-number">
              </b-col>

              <b-col class="form-group col-md-6 col-lg-4">
                <label for="program-number" class="font-weight-bold">Program Number</label>
                <input class="form-control" type="text" placeholder="Enter Program Number" v-model="programNumber" id="program-number">
              </b-col>

              <b-col class="form-group col-md-6 col-lg-4">
                <label for="treatment-type" class="font-weight-bold">Treatment Type</label>
                <input class="form-control" type="text" placeholder="Enter Treatment Type" v-model="treatmentType" id="treatment-type">
              </b-col>

              <b-col class="form-group col-md-6 col-lg-4">
                <label for="vendor-invoice-date" class="font-weight-bold">Vendor Invoice Date</label>
                <v-date-picker
                        id="vendor-invoice-date"
                        placeholder="Click to choose"
                        :input-props='{
                          class: "form-control",
                          placeholder: "Choose a date or leave blank",
                        }'
                        v-model="vendorInvoiceDate"
                        show-caps>
                </v-date-picker>
              </b-col>
            </b-row>
          </b-card>
          <b-card v-if="projectid">
            <h3 class="d-inline-block mb-3">Add Services</h3>
                       <div id ="date-picker-div">
                        <v-date-picker
                        v-if="preServiceData.length > 0"
                          class="vc-calendar"
                          :formats='formats'
                          v-model='allService_dates'
                          mode='range'
                          show-caps
                          v-b-tooltip.hover
                          @input="setDates()"
                            :input-props='{
                          placeholder: "Apply date to all services",
                        }'
                        >
                        </v-date-picker>
                        
            <b-button :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1" v-if="preServiceData.length > 0" @click="addAllLines()" class="btn-blue mb-2 btn-sm float-right" href="">
              Save All To Invoice <i class="fa fa-save"></i>
            </b-button>
              </div>

            <div class="table-responsive-sm clearfix">
              <form @submit.prevent="addPreService" class="position-relative">
                <div id="loading-gif-container" v-show="loading">
                  <rotate-square2 id="loading-gif"></rotate-square2>
                </div>
                <table class="table table-striped">
                   <thead class="d-none d-xl-table-header-group">
                    <tr class="service-rows" id="service-th">
                      <th class="actions fit"></th>
                      <th class="center service-name">Service</th>
                      <th class="center service-name">Code</th>
                      <th class="pre-datepickers right">Start - End Date</th>
                      <th class="uom-select">UOM <i class="fa fa-question-circle" v-b-tooltip.hover title="Unit Of Measurement"></i></th>
                      <th class="price center">Price/Unit</th>
                      <th class="price center">Discounted Price/unit</th>
                      <th class="price center">Quantity</th>
                      <th class="price center">Discount(%)</th>
                      <th class="right">Total</th>
                      <th class="save-button"></th>
                    </tr>
                  </thead>

                   <tbody>
                    <tr class="service-rows create-service" id="service-td" v-for="(service, i) in preServiceData" v-if="projectid">
                      <td class="d-xs-none d-lg-block actions fit border-0"><button v-if="preServiceData.length > 0" type="button" :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1" v-on:click="deletePreLineServices(i)" class="btn btn-sm btn-danger"><i class="fa fa-minus"></i></button></td>
                      <td class="center service-name">
                        <h6 class="d-xs-block d-xl-none mobile-header">Service</h6>
                        <div v-if="service.selected">
                          {{service.serviceid.name}}
                        </div>
                        <div class="d-xs-block d-xl-none">
                        
                          <multiselect
                            v-model="service.serviceid"
                            class="rt-vendor-service-select"
                            label="name"
                            :multiple="false"
                            tagPlaceholder=""
                            track-by="serviceid"
                            selectLabel=""
                            deselectLabel=""
                            :options="vendorServices"
                            @input="(service.uom = [service.serviceid.pu1, service.serviceid.uom1])"
                          >
                          <template slot="option" slot-scope="props">
                              <div class="option__desc"><span class="option__title">{{ props.option.name }} ({{ props.option.currency }})</span></div>
                            </template>
                        </multiselect>
                        </div>
                      </td>
                      <td class="center service-name">
                    
                        <h6 class="d-xs-block d-xl-none mobile-header">Code</h6>
                        <div v-if="service.selected">
                          {{service.serviceid.type}}
                        </div>
                        <div class="d-xs-block d-xl-none">
                          <multiselect
                            v-model="service.serviceid"
                            class="rt-vendor-service-select"
                            label="type"
                            tagPlaceholder=""
                            :multiple="false"
                            selectLabel=""
                            deselectLabel=""
                            track-by="serviceid"
                            :options="vendorServices"
                            @input="(service.uom = [service.serviceid.pu1, service.serviceid.uom1])"
                          >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc"><span class="option__title">{{ props.option.type }}</span></div>
                            </template>
                          </multiselect>
                        </div>
                      </td>
                      <td class="pre-datepickers right">
                        <h6 class="d-xs-block d-xl-none">Start - End Date</h6>
                        <v-date-picker
                          v-if="service.serviceid.name"
                          class="vc-calendar"
                          :formats='formats'
                          mode='range'
                          v-model='service.dates'
                          show-caps
                          v-b-tooltip.hover
                          @input="setQuantity(i)"
                        >
                        </v-date-picker>
                      </td>

                      <td class="left uom-select">                   
                        <h6 class="d-xs-block d-xl-none">Unit Of Measurement</h6>
                        <select v-validate="'required'" class="w-100 rt-select"  @change = "Pu_toggler(service,service.discount,service.uom[0])"
                         @name="service.uom" v-if="service.serviceid.name" :class="{'is-danger': errors.has('service.uom')}" v-model="service.uom" placeholder="Select">
                          <option selected :value="[service.serviceid.pu1, service.serviceid.uom1]">{{service.serviceid.uom1}}</option>
                          <option v-if="service.serviceid.pu2" :value="[service.serviceid.pu2, service.serviceid.uom2]">{{service.serviceid.uom2}}</option>
                          <option v-if="service.serviceid.pu3" :value="[service.serviceid.pu3, service.serviceid.uom3]">{{service.serviceid.uom3}}</option>
                        </select>
                      </td>

                      <td class="price left v-align-middle">
                        <h6 class="d-xs-block d-xl-none" style="margin-bottom: 13px;">Price</h6>
                        <span v-if="service.serviceid.name">${{formatPrice(service.uom[0])}}</span>
                      </td>
                      <td style="padding-bottom: 0.5rem;" class="price left">
                        <h6 class="d-xs-block d-xl-none">Discounted Price/Unit</h6>

        <input v-if = "service.serviceid.name" class="rt-round" style="width:110px;"
     v-model ="service.discounted_price_per_unit"   @input="Discount(service,service.discounted_price_per_unit,service.uom[0])">

                   </td>

                      <td style="padding-bottom: 0.5rem;" class="price left">
                        <h6 class="d-xs-block d-xl-none">Quantity</h6>
                        <input v-if="service.serviceid.name" :class="{warning: /day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity}" step="0.01" class="rt-round" v-model="service.quantity" >
                      
                        <span class="ml-1" v-if="/day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity">
                          <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end (' + (parseInt($moment(service.dates.end).diff($moment(service.dates.start), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
                        </span>
                      </td>
                      <td style="padding-bottom: 0.5rem;" class="price left">
                        <h6 class="d-xs-block d-xl-none">Discount (%)</h6>

<input type="text" v-if="service.serviceid.name" :id="`${service.serviceid.serviceid + service.uom[0]}`" class="rt-round" v-model="service.discount" @input="discounted(service,service.discount,service.uom[0])" style="width:110px;">

   
      <b-popover delay="1000000" :show ="service.discount > 100 || service.discount < 0" :target="`${service.serviceid.serviceid + service.uom[0]}`"  title="Warning">
      <span>Discount should be between 0%-100%</span><hr />
<span>Discounted price per unit should be between $0-price/unit</span>
      </b-popover>
 
                      </td>
                      <td class="right v-align-middle">
                        <h6 class="d-xs-block d-xl-none" style="margin-bottom: 13px;">Total</h6>
                        <span v-if="service.serviceid.name">${{formatPrice((service.quantity * service.uom[0]) - ((service.discount/100) * service.quantity * service.uom[0]))}}</span>
                      </td>
                      <td class="save-button" style="margin-left:65px;">
                        <button :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1" @click="addPreService(service,i)" v-if="service.quantity >= 0 && service.uom && service.discount >= 0 && service.discount <= 100" type="submit" v-b-tooltip.hover title="Save Service To Invoice" class="btn btn-sm btn-blue d-xs-block d-xl-none"><i class="fa fa-save"></i></button>
                        <button :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1 || !service.selected" @click="addPreService(service, i)" v-if="service.quantity >= 0 && service.uom && service.discount >= 0 && service.discount <= 100" type="submit" v-b-tooltip.hover title="Save Service To Invoice" class="d-none d-xl-block btn btn-sm btn-blue" style="margin-left:15px;"><i class="fa fa-save"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class=""><b-button v-if="projectid" :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1" @click="showServiceModal(preServiceData.length,'name','flag')" variant="success" class="mt-3 mb-3 btn-sm mr-2 float-left" href="">
                  Add New Line <i class="fa fa-plus"></i>
                </b-button>
                </div>
              </form>
            </div>
          </b-card>
          <b-card v-if="unassigned.length > 0 || merged.length > 0">
            <h3 class="d-inline mt-3" v-if="unassigned.length > 0 || merged.length > 0">Service Lines <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="You may edit a service by clicking on it."></i></h3>
            <button type="button" class="btn btn-sm btn-danger float-right" @click="removeAll()" name="button"><strong>Remove All AFE'S / PO'S <i class="fa fa-times"></i></strong></button>

            <b-row>
              <b-col class="col-12 col-lg-12 mr-auto mt-2">
                <div class="table-responsive-sm">
                <table class="table vendor-invoice-unassigned vendor-service-table">
                  <thead class="d-none d-xl-table-header-group">
                    <tr>
                      <th class="border-0 actions fit"></th>
                      <th class="border-0">Service</th>
                      <th class="border-0">Code</th>
                      <th class="border-0 datepickers">Start - End Date</th>
                      <th class="border-0">UOM</th>
                      <th class="border-0">Price/Unit</th>
                      <!-- settd it in web view after submission -->
                       <th class="border-0">Discounted Price/Unit</th>
                      <th class="border-0">Quantity</th>
                      <th class="border-0">Discount</th>
                      <th class="border-0">Total</th>
                      <th class="border-0"></th>
                    </tr>
                  </thead>
                  <tbody v-for="service in unassigned">
                    <tr class="service-rows mt-2 service-bg unassigned rt-wss-create-afe" :class="{editing: service == editedLine}">
                      <td style="line-height: 55px;" class="d-xs-none d-lg-block actions fit"><button type="button" :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1" v-on:click="removeService(service)" class="btn btn-sm btn-danger"><i class="fa fa-minus"></i></button></td>
                      <td class="center price v-align-middle">
                        <div class="view" @click="editData(service)">
                          <h4 class="d-xs-block d-xl-none">Service: {{service.name}}</h4>
                          <span class="d-none d-xl-block"><strong>{{service.name}}</strong></span>
                        </div>
                        <div class="edit">
                          <!-- after submission on edit -->
                          <h4 class="d-xs-block d-xl-none">Service: {{service.name}}</h4>
                          <span class="mb-0 d-none d-xl-block">{{service.name}}</span>
                        </div>
                      </td>
                      <td class="center price v-align-middle">
                        <div class="view" @click="editData(service)">
                          <h6 class="d-xs-block d-xl-none">Code: {{service.type}}</h6>
                          <span class="d-none d-xl-block">{{service.type}}</span>
                        </div>
                        <div class="edit">
                          <h6 class="d-xs-block d-xl-none">Code: {{service.type}}</h6>
                          <span class="d-none d-xl-block">{{service.type}}</span>
                        </div>
                      </td>
                      <td class="center price datepickers v-align-middle">
                        <div class="view" @click="editData(service)">
                          <h6 class="d-xs-block d-xl-none">Start - End Date</h6>
                          {{service.dates.start | moment("MM/DD/YYYY")}} - {{service.dates.end | moment("MM/DD/YYYY")}} <br><span style="font-size: 10px;">({{(parseInt($moment(service.dates.end).diff($moment(service.dates.start), 'days')) + 1)}} Days)</span>
                        </div>
                        <div class="edit">
                          <v-date-picker
                            v-if="service == editedLine"
                            class="vc-calendar"
                            :formats='formats'
                            mode='range'
                            v-model='service.dates'
                            show-caps
                            @input="updateEditQuantity(service)"
                            >
                          </v-date-picker>
                        </div>
                      </td>
                      <td class="left price v-align-middle">
                        <div class="view" @click="editData(service)">
                       
                          <h6 class="d-xs-block d-xl-none zbg-green">Unit Of Measurement: {{service.uom[1]}}</h6>
                          <span class="d-none d-xl-block">{{service.uom[1]}}</span>
                        </div>
                        <div class="edit">
                          <select v-validate="'required'" class="w-100 rt-select" @change = "Pu_toggler(service,service.discount,service.uom[0])"
                          name="service.uom" v-if="service.serviceid" :class="{'is-danger': errors.has('service.uom')}" v-model="service.uom" placeholder="Select">
                            <option selected :value="[service.serviceid.pu1, service.serviceid.uom1]">{{service.serviceid.uom1}}</option>
                            <option v-if="service.serviceid.pu2" :value="[service.serviceid.pu2, service.serviceid.uom2]">{{service.serviceid.uom2}}</option>
                            <option v-if="service.serviceid.pu3" :value="[service.serviceid.pu3, service.serviceid.uom3]">{{service.serviceid.uom3}}</option>
                          </select>
                        </div>
                      </td>
                      <td class="price left v-align-middle">
                        <div class="view" @click="editData(service)">
                          <h6 class="d-xs-block d-xl-none">Price: ${{formatPrice(service.pu)}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice(service.pu)}}</span>
                        </div>
                        <div class="edit">
                          <h6 class="d-xs-block d-xl-none">Price: ${{formatPrice(service.uom[0])}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice(service.uom[0])}}</span>
                        </div>
                      </td>
<!-- after submission and on eddition  -->
                         <td class="price left v-align-middle">
                        <div class="view" @click="editData(service)">
                        
                          <h6 class="d-xs-block d-xl-none">Discounted Price/Unit:${{formatPrice(service.discounted_price_per_unit)}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice(service.discounted_price_per_unit)}}</span>
                    
                      </div>
          
                        <div class="edit">

  
    <h6 class="d-xs-block d-xl-none">Discounted Price/Unit:$
<input v-if = "service.name"  
style="width:110px;" v-model ="service.discounted_price_per_unit"  @input="Discount(service,service.discounted_price_per_unit,service.uom[0])">                            
</h6>
  
<span class="d-none d-xl-block">
<input v-if = "service.name"  v-model ="service.discounted_price_per_unit"  
style="width:110px;" @input="Discount(service,service.discounted_price_per_unit,service.uom[0])">   
  </span>
                      
 </div>
 </td>
                      <td class="price left v-align-middle">
                        <div class="view" @click="editData(service)">
                          <h6 class="d-xs-block d-xl-none">Quantity1: {{parseFloat(service.quantity)}}</h6>
                          <span class="d-none d-xl-inline-block">{{parseFloat(service.quantity)}}</span>
                          <span class="ml-1" v-if="/day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity">
                            <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end dates (' + (parseInt($moment(service.dates.end).diff($moment(service.dates.start), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
                          </span>
                        </div>
                      
                        <div class="edit">
                
                          <input :class="{warning: /day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity}" step="0.01" class="rt-round" v-model="service.quantity">
                          <span class="ml-1" v-if="/day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity">
                            <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end (' + (parseInt($moment(service.dates.end).diff($moment(service.dates.start), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
                          </span>
                        </div>
                      </td>
                    
                      <td class="price left v-align-middle">
                        <div class="view" @click="editData(service)">
                          <!-- after submission data -->
                          <h6 class="d-xs-block d-xl-none">Discount: {{formatPrice(service.discount)}}%</h6>
                          <span class="d-none d-xl-block">{{formatPrice(service.discount)}}%</span>
                        </div>
                        <div class="edit">
                         
<input 
style="width:110px;" type="text" :id="`${service.serviceid.ptn + service.uom[0]}`" v-if="service.name" class="rt-round" v-model="service.discount" @input="discounted(service,service.discount,service.uom[0])">

      <b-popover delay="60000" :show ="service.discount > 100 || service.discount < 0" :target="`${service.serviceid.ptn + service.uom[0]}`" title="Warning">
      <span>Discount should be between 0%-100%</span><hr/>
      <span>Discounted price per unit should be between $0-price/unit</span>
      </b-popover>
 

                        </div>
                      </td>
                      
                      <td class="right v-align-middle">
                        <div class="view" @click="editData(service)">
                          <h6 class="d-xs-block d-xl-none">Total: ${{formatPrice((service.quantity * service.pu) - ((service.discount/100) * service.quantity * service.pu))}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice((service.quantity * service.pu) - ((service.discount/100) * service.quantity * service.pu))}}</span>
                        </div>
                        <div class="edit">
                          <h6 class="d-xs-block d-xl-none">Total: ${{formatPrice((service.quantity * service.pu) - ((service.discount/100) * service.quantity * service.pu))}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice((service.quantity * service.pu) - ((service.discount/100) * service.quantity * service.pu))}}</span>
                        </div>
                      </td>
                      <td>
                        <div class="view">
                          <b-dropdown variant="primary" size="sm" right :disabled="disableEdits == 1" text="Select Action" class="m-md-2 wss-action-dropdown">
                            <b-dropdown-item v-on:click="addNewUnassignedAfeLine(service, service)">AFE <i class="ml-1 fa fa-plus-circle"></i></b-dropdown-item>
                          </b-dropdown>
                        </div>
                        <div class="edit">
                          <button v-if="service.quantity >= 0 && service.uom && service.discount >= 0 && service.discount <= 100" class="edit btn btn-sm btn-primary" type="button" @click="editService(service)" style="margin-left:15px;"><i class="fa fa-edit"></i></button>
                        </div>
                      </td>
                    </tr>
                    <tr :class="{createAfe: service != createdUnassignedServiceLine}" class="rt-wss-create-afe">
                      <td class="p-0 show" colspan="100%">
                        <div class="table-responsive-sm">
                          <table class="table no-bg afenum mb-0">
                            <thead class="rt-border">
                              <tr class="afe-rows">
                                <th class="actions fit"></th>
                                <th class="center"><strong>AFE / PO</strong></th>
                                <th class="center"><strong>% <i class="fa fa-question-circle" v-b-tooltip.hover title="AFE Percentage"></i></strong></th>
                                <th class="center"><strong>Cost Code Chain</strong></th>
                                <!-- <th class="center"><strong>Cost Code #2 / Minor</strong></th>
                                <th class="center"><strong>Cost Code #3 / Description</strong></th>
                                <th class="center"><strong>Majo/ Minor/ Description-1</strong></th> -->
                                <th class="center"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <button type="button" v-on:click="cancelAfe()" v-b-tooltip.hover title="AFE / PO Cancel" class="mt-1 fit btn btn-sm btn-danger"><i class="fa fa-times-circle"></i></button>
                                </td>
                                <td>
                                  <h6 class="d-xs-block d-xl-none">AFE / PO</h6>
                                  <v-select
                                    v-if="service == createdUnassignedServiceLine"
                                    v-model="createAfenum"
                                    label="afenum"
                                    :options="createAfeData"
                                    :value="afenum"
                                    @change="getcc1Create()"
                                  ></v-select>
                                </td>
                                <td>
                                  <h6 class="d-xs-block d-xl-none">Percentage</h6>
                                  <input type="number" class="rt-percent" step="any" v-model="percentage">
                                </td>

                    
                    <td>
                  <v-select
                    :options="cc1CreateData"
                    class="mt-2"
                    :filterable="true"
                    :label="`${search_cc}`"
                    v-model="CC_data"
                  >
                    <template #list-footer>
                      <li ref="load" class="loader">
                        Loading more options...
                      </li>
                    </template>
                    <template v-slot:option="option">
                      <span>{{ option.costcode1 }}</span>
                      <span>-{{ option.costcode2 }}</span>
                      <span>-{{ option.costcode3 }}</span>
                    </template>
                    <template #selected-option="{ costcode1, costcode2, costcode3, option, deselect, multiple, disabled }">
                      <span>{{ costcode1 }}</span>
                      <span>-{{ costcode2 }}</span>
                      <span>-{{ costcode3 }}</span>
                    </template>
                  </v-select>
                    </td>
                    <td class="actions">
                      <button type="button" v-on:click="addAfeUnassignedLine(service)" class="btn btn-sm mt-1 btn-success mr-2">Add <i class="fa fa-plus"></i></button>
                      <!-- {{service}} -->
                      <button type="button" v-on:click="addAfeToAllInvoiceServices(service)" class="btn btn-sm mt-1 btn-success">Add To All <i class="fa fa-plus"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
                </div>
              </td>
            </tr>
          </tbody>
                  <tbody v-for="(afe, index) in merged">
                    <tr class="service-rows mt-2 service-bg rt-wss-create-afe" v-for="(service, serviceIndex) in afe" :class="{editing: service == editedLine}">
                  
                      <td style="line-height: 55px;" class="d-xs-none d-lg-block actions fit border-0"><button type="button" :disabled="disableEdits == 1" v-on:click="removeService(service)" class="btn btn-sm btn-danger"><i class="fa fa-minus"></i></button></td>
                      <td class="center price v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h4 class="d-xs-block d-xl-none">Service: {{service.name}}</h4>
                          <span class="d-none d-xl-block"><strong>{{service.name}}</strong></span>
                        </div>
                        <div class="edit">
                          <h4 class="d-xs-block d-xl-none">Service: {{service.name}}</h4>
                          <span class="mb-0 d-none d-xl-block">{{service.name}}</span>
                        </div>
                      </td>
                      <td class="center price v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Code: {{service.type}}</h6>
                          <span class="d-none d-xl-block">{{service.type}}</span>
                        </div>
                        <div class="edit">
                          <h6 class="d-xs-block d-xl-none">Code: {{service.type}}</h6>
                          <span class="d-none d-xl-block">{{service.type}}</span>
                        </div>
                      </td>
                      <td class="center datepickers v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Start - End Date</h6>
                          {{service.dates.start | moment("MM/DD/YYYY")}} - {{service.dates.end | moment("MM/DD/YYYY")}} <br><span style="font-size: 10px;">({{(parseInt($moment(service.dates.end).diff($moment(service.dates.start), 'days')) + 1)}} Days)</span>
                        </div>
                        <div class="edit">
                          <v-date-picker
                            v-if="service == editedLine"
                            class="vc-calendar"
                            :formats='formats'
                            @input="updateEditQuantity(service, index, serviceIndex)"
                            mode='range'
                            v-model='service.dates'
                            show-caps
                            >
                          </v-date-picker>
                        </div>
                      </td>
                      <td class="left price v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Unit Of Measurement: {{service.uom[1]}}</h6>
                          <span class="d-none d-xl-block">{{service.uom[1]}}</span>
                        </div>
                        <div class="edit">
                          <select v-validate="'required'" class="w-100 rt-select" @change = "Pu_toggler(service,service.discount,service.uom[0])"  
                          name="service.uom" v-if="service.serviceid" :class="{'is-danger': errors.has('service.uom')}" v-model="service.uom" placeholder="Select">
                            <option selected :value="[service.serviceid.pu1, service.serviceid.uom1]">{{service.serviceid.uom1}}</option>
                            <option v-if="service.serviceid.pu2" :value="[service.serviceid.pu2, service.serviceid.uom2]">{{service.serviceid.uom2}}</option>
                            <option v-if="service.serviceid.pu3" :value="[service.serviceid.pu3, service.serviceid.uom3]">{{service.serviceid.uom3}}</option>
                          </select>
                        </div>
                      </td>
                      <td class="price left v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Price: ${{formatPrice(service.pu)}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice(service.pu)}}</span>
                        </div>
                        <div class="edit">
                          <h6 class="d-xs-block d-xl-none">Price: ${{formatPrice(service.uom[0])}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice(service.uom[0])}}</span>
                        </div>
                      </td>

                    <td class="price left v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Discounted Price/Unit:{{formatPrice(service.discounted_price_per_unit)}} </h6>
                          <span class="d-none d-xl-block">{{formatPrice(service.discounted_price_per_unit)}}</span>
                        </div>
                        <div class="edit">
                          <h6 class="d-xs-block d-xl-none">Discounted Price/Unit:<input style="width:110px;" v-if = "service.name"  v-model ="service.discounted_price_per_unit"   @input="Discount(service,service.discounted_price_per_unit,formatPrice(service.uom[0]).replace(',',''))"></h6>
                          <span class="d-none d-xl-block"><input style="width:110px;" v-if = "service.name"  v-model ="service.discounted_price_per_unit"   @input="Discount(service,service.discounted_price_per_unit,formatPrice(service.uom[0]).replace(',',''))"></span>
                       
                        </div>
                      </td>

                      <td class="price left v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Quantity: {{parseFloat(service.quantity)}}</h6>
                          <span class="d-none d-xl-inline-block">{{parseFloat(service.quantity)}}</span>
                          <span class="ml-1" v-if="/day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity">
                            <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end dates (' + (parseInt($moment(service.dates.end).diff($moment(service.dates.start), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
                          </span>
                        </div>
                        <div class="edit">
                          <input :class="{warning: /day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity}" step="0.01" class="rt-round" v-model="service.quantity">
                          <span class="ml-1" v-if="/day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity">
                            <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end (' + (parseInt($moment(service.dates.end).diff($moment(service.dates.start), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
                          </span>
                        </div>
                      </td>
                      <td class="price left v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Discount:{{formatPrice(service.discount)}}%</h6>
                          <span class="d-none d-xl-block">{{formatPrice(service.discount)}}%</span>
                        </div>
                        <div class="edit">
     <input style="width:110px;" type="text" :id="`${service.serviceid.ptn + service.uom[0]}`" v-if="service.name" class="rt-round" v-model="service.discount" @input="discounted(service,service.discount,service.uom[0])">
      <b-popover delay="60000" :show ="service.discount > 100 || service.discount < 0" :target="`${service.serviceid.ptn + service.uom[0]}`" title="Warning">
      <span>Discount should be between 0%-100%</span><hr/>
      <span>Discounted price per unit should be between $0-price/unit</span>
      </b-popover>
                        </div>
                      </td>
                      <td class="right v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Total: ${{formatPrice((service.quantity * service.pu) - ((service.discount/100) * service.quantity * service.pu))}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice((service.quantity * service.pu) - ((service.discount/100) * service.quantity * service.pu))}}</span>
                        </div>
                        <div class="edit">
                          <h6 class="d-xs-block d-xl-none">Total: ${{formatPrice((service.quantity * service.pu) - ((service.discount/100) * service.quantity * service.pu))}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice((service.quantity * service.pu) - ((service.discount/100) * service.quantity * service.pu))}}</span>
                        </div>
                      </td>
                      <td>
                        <div class="view">
                          <b-dropdown variant="primary" size="sm" right :disabled="disableEdits == 1" text="Select Action" class="m-md-2 wss-action-dropdown">
                             <b-dropdown-item v-on:click="addNewAfeLine(afe, service)">AFE <i class="ml-1 fa fa-plus-circle"></i></b-dropdown-item>
                             <b-dropdown-item v-on:click="removeAllAfe(service)">Remove All Assigned AFES <i class="ml-1 fa fa-times"></i></b-dropdown-item>
                          </b-dropdown>
                        </div>
                        <div class="edit">
                          <button v-if="service.quantity >= 0 && service.uom && service.discount >= 0 && service.discount <= 100" class="edit btn btn-sm btn-primary" type="button" @click="editService(service)"><i class="fa fa-edit"></i></button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="p-0" colspan="100%">
                        <div class="table-responsive-sm">
                          <table class="table no-bg afenum mb-0">
                            <thead class="rt-border">
                              <tr class="afe-rows">
                                <th class="actions fit"></th>
                                <th class="center"><strong>AFE / PO</strong></th>
                                <th class="center"><strong> AFE Percentage % <i class="fa fa-question-circle" v-b-tooltip.hover title="AFE Percentage"></i></strong></th>
                                <th class="center"><strong> Cost Code Chain </strong></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(line, aIndex) in afe[0].afes" :class="{editingAfe: line == editedAfeLine}" class="rt-wss-create-afe rt-mobile-indent">
                                <td class="d-xs-none d-lg-block actions fit border-0"></td>
                                <td>
                                  <div class="view" @click="editAfe = 1, editAfeLine(line, index, aIndex, afes, afe)">
                                    <h4 class="d-block d-xl-none">AFE</h4><button :disabled="disableEdits == 1" type="button" v-on:click="removeAllAfe(afe[0].afes)" class="fit btn btn-danger float-right d-inline-block d-xl-none ml-2"><i class="fa fa-minus-circle"></i></button><button @click="editAfe = 1, editAfeLine(line, index, aIndex, afes, afe)" class="btn btn-primary d-inline-block d-xl-none float-right"><i class="fa fa-edit"></i></button>
                                    <i class="fa fa-chevron-circle-right"></i> {{line.afenum}}
                                  </div>
                                  <div class="edit" @click="editAfe = 0">
                                    <h6 class="d-xs-block d-xl-none">AFE / PO</h6>
                                    <v-select
                                      v-if="line == editedAfeLine"
                                      v-model="line.afenum"
                                      label="afenum"
                                      :options="serviceChainData"
                                      :value="afenum"
                                    ></v-select>
                                  </div>
                                </td>
                                <td>
                                  <div class="view" @click="editAfe = 1, editAfeLine(line, index, aIndex, afes, afe)">
                                    <h6 class="d-xs-block d-xl-none">Percentage</h6>
                                    {{line.percentage}}
                                  </div>
                                  <div class="edit">
                                    <h6 class="d-xs-block d-xl-none">Percentage</h6>
                                    <input type="number" class="rt-percent" step="any" v-model="percentage">
                                  </div>
                                </td>
                                <td>
                                  <div class="view" @click="editAfe = 1, editAfeLine(line, index, aIndex, afes, afe)">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #1 / Major</h6>
                                    {{line.ccone_code }} -- {{line.cctwo_code }} -- {{line.ccthree_code }}
                                  </div>
                                   <div class="edit" @click="editAfe = 0">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #1 / Major</h6>
                                  <v-select
                                  :options="cc1Data"
                                  v-if="line == editedAfeLine"
                                  class="mt-2"
                                  :filterable="true"
                                  :label="`${search_cc}`"
                                  v-model="CC_data"
                                >
                                  <template #list-footer>
                                    <li ref="load" class="loader">
                                      Loading more options...
                                    </li>
                                  </template>
                                  <template v-slot:option="option">
                                    <span>{{ option.costcode1 }}</span>
                                    <span>-{{ option.costcode2 }}</span>
                                    <span>-{{ option.costcode3 }}</span>
                                  </template>
                                  <template #selected-option="{ costcode1, costcode2, costcode3, option, deselect, multiple, disabled }">
                                    <span>{{ costcode1 }}</span>
                                    <span>-{{ costcode2 }}</span>
                                    <span>-{{ costcode3 }}</span>
                                  </template>
                                </v-select>
                                      </div> 
                                      </td>
                                <td class="actions">
                                  <div class="edit">
                                    <button type="button" v-on:click="editAddAfeToInvoice(line, afe, index, aIndex, afes)" class="btn btn-primary"><i class="fa fa-edit"></i></button>
                                  </div>
                                </td>
                              </tr> 
                              <tr :class="{createAfe: afe != createdServiceLine}" class="rt-wss-create-afe">
                                <td class="show">
                                  <button type="button" v-on:click="cancelAfe()" v-b-tooltip.hover title="AFE Cancel" class="fit btn btn-sm btn-danger"><i class="fa fa-times-circle"></i></button>
                                </td>
                                <td>
                                  <div class="show">
                                    <h6 class="d-xs-block d-xl-none">AFE / PO</h6>
                                    <v-select
                                      v-if="afe == createdServiceLine"
                                      v-model="createAfenum"
                                      label="afenum"
                                      :options="createAfeData"
                                      :value="afenum"
                                    ></v-select>
                                  </div>
                                </td>
                                <td>
                                  <div class="show">
                                    <h6 class="d-xs-block d-xl-none">Percentage</h6>
                                    <input type="number" class="rt-percent" step="any" v-model="percentage">
                                  </div>
                                </td> 
                                <td>
                                  <div class="show">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #1 / Major</h6>
                               <v-select
                                :options="cc1CreateData"
                                class="mt-2"
                                :filterable="true"
                                :label="`${search_cc}`"
                                v-model="CC_data"
                               >
                            <template #list-footer>
                              <li ref="load" class="loader">
                                Loading more options...
                              </li>
                            </template>
                            <template v-slot:option="option">
                              <span>{{ option.costcode1 }}</span>
                              <span>-{{ option.costcode2 }}</span>
                              <span>-{{ option.costcode3 }}</span>
                            </template>
                            <template #selected-option="{ costcode1, costcode2, costcode3, option, deselect, multiple, disabled }">
                              <span>{{ costcode1 }}</span>
                              <span>-{{ costcode2 }}</span>
                              <span>-{{ costcode3 }}</span>
                            </template>
                          </v-select>
                                  </div>
                                </td> 

                                <td class="actions">
                                  <div class="show">
                                    <button type="button" v-on:click="addAfeLine(afe, line)" class="btn btn-sm btn-success mr-2">Add <i class="fa fa-plus"></i></button>
                                    <button type="button" v-on:click="addAfeToAllInvoiceServices(index, afe)" class="btn btn-sm btn-success">Add To All <i class="fa fa-plus"></i></button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </b-col>
              <!-- Total Tables -->
              <b-col lg="6" class="col-12 ml-auto">
                <table class="mt-1 table table-clear">
                  <tbody>
                    <tr>
                      <td class="left"><strong>Subtotal</strong></td>
                      <td class="right">${{formatPrice(subtotal)}}</td>
                    </tr>
                    <tr>
                      <td class="left"><strong>GST</strong></td>
                      <td class="right">
                        <input class="days" type="text" v-model="gst" @input="gsttotal" step="any"> %</td>
                    </tr>
                    <tr>
                      <td class="left"><strong>Tax</strong></td>
                       <td class="right"><input class="days" type="text" v-model="tax" @blur="handleBlur" @input="taxtotal" step="any"> $</td>
                    </tr>
                    <tr>
                      <td class="left"><strong>Total</strong></td>
                      <td class="right"><strong>${{formatPrice(totalinvoice)}}</strong></td>
                    </tr>
                    <tr>
                      <hr>
                    </tr>
                    <tr>
                      <td class="left"><strong>Subtotal</strong></td>
                      <td class="right">${{formatPrice(subtotal)}}</td>
                    </tr>
                    <tr>
                      <td class="left"><strong>Quick Pay Discount <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="Discount given to get paid quicker"></i></strong></td>
                      <td class="right">
                        <input type="text" class="days" v-model="quickpay"> %
                      </td>
                    </tr>
                    <tr v-if="quickpay > 0">
                      <td class="left"><strong>Quick Pay Discount Total</strong></td>
                      <td class="right">${{formatPrice(qpdiscount)}}</td>
                    </tr>

                    <tr v-if="quickpay > 0">
                      <td class="left"><strong>Quick Pay Subtotal</strong></td>
                      <td class="right">${{formatPrice(quickpaysubtotal)}}</td>
                    </tr>

                    <tr v-if="quickpay > 0">
                      <td class="left"><strong>Quick Pay Tax</strong></td>
                      <td class="right">${{formatPrice(qpgstsubtotal)}}</td>
                    </tr>
                    <tr v-if="quickpay > 0">
                      <td class="left"><strong>Quick Pay Net Days <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="Days given for quickpay payment"></i></strong></td>
                      <td class="right"><input class="days" type="number" value="0" min="0" v-model="days"></td>
                    </tr>
                    <tr v-if="quickpay > 0">
                      <td class="left"><strong>Quick Pay Total</strong></td>
                      <td class="right"><strong>${{formatPrice(quickpaysubtotal + qpgstsubtotal)}}</strong></td>
                    </tr>
                  </tbody>
                </table>
                <div style="padding-left: 0.75rem">
                  <h5>Comments <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="Comments will be seen by all user levels and be attached on invoice"></i></h5>
                  <textarea name="comments" v-model="comments" id="" class="w-100 rounded border mt-2 mb-4" rows="3"></textarea>
                  <vue-dropzone v-on:vdropzone-success="redirectUser()" class="mb-4" ref="myVueDropzone" v-on:vdropzone-sending="sendingEvent" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                  <div class="mb-4" v-if="$session.get('user') == 'accountant' && selectedProject">
                    <select v-if="selectedProject[0].acc_project == 1" v-model="directRegular">
                      <option value="false">Regular Submission (PM)</option>
                      <option value="true">Manager 1</option>
                      <option value="true">Manager 2</option>
                      <option value="true">Manager 3</option>
                      <option value="true">Manager 4</option>
                      <option value="true">Manager 5</option>
                    </select>
                  </div>
                  <button class="btn btn-info mr-4 mb-4" style="color:white;" @click="submitInvoice(1)">Save As Draft <i class="fa fa-save"></i></button>
                  <button type="submit" @click="submitInvoice(0)" class="btn btn-success mb-4"><i class="fa fa-check"></i> Submit Invoice</button>
                  <div v-show="invoiceError" class="d-block">
                    <i v-show="invoiceError" class="fa fa-exclamation-triangle"></i><span v-show="invoiceError" class="help">Invoice Error. Please fix invoice or contact admin.</span>
                  </div>
                </div>
              </b-col>
            </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import Vue from 'vue'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import { Validator } from 'vee-validate';
import {Circle4} from 'vue-loading-spinner'
import {RotateSquare2} from 'vue-loading-spinner'
import Multiselect from 'vue-multiselect'
import {ClientTable} from 'vue-tables-2'
import * as jwt_decode from 'jwt-decode';
import { backend_url } from '../../config';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

Vue.use(ClientTable)

export default {
  name: 'VendorCreateInvoice',
  components: {
    vSelect,
    Datepicker,
    RotateSquare2,
    ClientTable,
    Circle4,
    Multiselect,
    vueDropzone: vue2Dropzone
  },
 
  props : ['fake_vendor'],
  
  data () {
    return {
      allService_dates:"",
      avatar: "",
      vendorAvatar: "",
      columns: ['name','type','uom1','uom2','uom3'],
      options: {
        headings: {
          name: "Service Name",
          type: "Code",
          uom1: "Uom1",
          umo2: "Uom2",
          uom3: "Uom3"
        },
        sortable: ['name', 'type'],
        filterable: ['name', 'type'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      modalShow:false,
      applyDates_all:false,
      vendoreService_toggler:[],
      dropzoneOptions: {
        url: `${backend_url}/attachment/upload`,
        // https://rtnest.org/attachment/upload
        thumbnailWidth: 150,
        autoProcessQueue: false,
        acceptedFiles: 'application/pdf, application/vnd.ms-excel, application/msword, image/jpeg, image/png',
        duplicateCheck: true,
        maxFilesize: 9.9, // MB
        maxFiles: 3,
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> Upload Files",
        headers: {
          'Authorization': 'Bearer ' + Vue.prototype.$session.get('jwt')
        }
      },
      showCode: false,
      serviceSelectInModal: [],
      serviceModalIndex: 0,
      editedLine: null,
      editedAfeLine: null,
      editMode: false,
      gst: 5,
      tax: 0,
      preServiceData: [],
      serviceData: [],
      companyInfo: [],
      today: new Date(),
      uom: null,
      companyData: [],
      companyId: 0,
      projectid: 0,
      projectData: [],
      vendorDetails: [],
      name: 0,
      vwref: 1,
      start_date: '',
      end_date: '',
      quantity: 0,
      price: 0,
      loading: false,
      discount: 0,
      currency: 'CAD',
      pu: 0,
      afenum: '',
      ccone_code: '',
      cctwo_code: '',
      ccthree_code: '',
      addAfeButtons: 1,
      addAfeShow: 0,
      afe: [],
      percent: 0,
      afes: [],
      quickpay: 0,
      services: [],
      vendorServices: [],
      i: null,
      uomText: '',
      serviceText: '',
      comments: "",
      vsid: 0,
      days: "",
      addToInvoice: 1,
      chainServices: [],
      invoiceError: false,
      serviceChainData: [],
      afesSorted: [],
      uwiData: [],
      cc1Data: [],
      cc2Data: [],
      cc3Data: [],
      fieldTicketNumber:'',
      vendorInvoiceNumber:'',
      serviceOrderNumber:'',
      programNumber:'',
      treatmentType:'',
      vendorInvoiceDate:new Date,
      cc1: [],
      cc2: [],
      cc3: [],
      loadingCompany: false,
      servicenameSort: "",
      preIndex: 0,
      line: "",
      lineAfe: "",
      beforeEditCache: [],
      beforeEditCacheCatch: 0,
      beforeEditCacheIndex: 0,
      beforeEditAfeCache: null,
      beforeEditAfeCacheCatch: 0,
      beforeEditAfeCacheIndex: 0,
      preset: 0,
      editAfe: 0,
      presetData: [],
      changeDates: 0,
      preset_date: {"start": new Date, "end": new Date},
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'], // Only for `v-date-picker`
        dayPopover: 'L', // Only for `v-date-picker`
      },
      discounted_price_per_unit:0,
      percentage: 0,
      createdServiceLine: 0,
      createAfenum: [],
      createCC1Percentage: 100,
      createCC1Code: [],
      createCC2Code: [],
      createCC3Code: [],
      createAfeData: [],
      cc1CreateData: [],
      cc2CreateData: [],
      cc3CreateData: [],
      createAfe: 0,
      disableEdits: 0,
      afes: [],
      order: "",
      ascDesc: 'asc',
      toggle: false,
      createdUnassignedServiceLine: [],
      createdServiceLineItem: [],
      editAfeChainLine: [],
      loading: false,
      lineLoading: false,
      disableImport: false,
      directRegular: false,
      selectedProject: [],
      invoiceid: 0,
      usersName: "",
      price_per_unit:0,
      projectTypeData: [{ 'projecttype': 'Resource Project'},{'projecttype': 'Operation Project'}],
      projectType:'',
      CC_data: '',
      search:'',
      search_cc:'costcode1',
      vendorId:0,
      toggle_edit_afe: true,
      toggle_add_afe_line: false,
      projectname:'',
      vwref_list:[],
      vwrefs:''
    }
  },
  watch: {
    invoiceid: function(val){
      console.log('invoiceid' + val)
      if(val > 0){
        console.log('invoiceid > 0' + val)
        //this.$refs.myVueDropzone.autoProcessQueue = true;
        this.$refs.myVueDropzone.processQueue();
      }
    }
  },
  methods: {

//for price per unit and discounted price per unit value;
// toggles the discount price per unit value;
Pu_toggler(service,discount,pu){


if(!discount){


service.discounted_price_per_unit = pu.toFixed(2);

return service.discounted_price_per_unit;

}else{

 service.discounted_price_per_unit = pu*(1-discount/100).toFixed(2);

 return  service.discounted_price_per_unit;

}

},
    redirectUser(){

      if(!this.fake_vendor){
        this.$router.push('/vendor')
      } else {
        this.$router.push('/accountant/view-invoices')
      }
    },
    sendingEvent (file, xhr, formData) {
      formData.append('invoiceid', this.invoiceid);
      formData.append('companyid', this.companyId);
      formData.append('projectid', this.projectid);
    },
    deselectService(service,pu){
        this.serviceSelectInModal = service;  
     
     let index = this.preServiceData.findIndex(services=> services.serviceid.serviceid == service.serviceid);
     let index1 = this.vendoreService_toggler.findIndex(services=> services == service.serviceid)
    this.preServiceData.splice(index,1);
    this.vendoreService_toggler.splice(index1,1);
    
   
   },
    selectService(service,pu){
      this.serviceSelectInModal = service;
      if(this.serviceSelectInModal.length == 0){
         this.$refs['service-modal'].hide();
        return null;
      }

       this.preServiceData.push(Vue.util.extend({}, {
        serviceid: [],
        dates: {"start": new Date, "end": new Date},
        uom: [],
        quantity: 1,
        discount: 0.00,
        vsid: 0,
        id: 0,
        percentage:  0,
        pu: 0,
        total: 0
      }));

      this.preServiceData[this.preServiceData.length-1].uom = [this.serviceSelectInModal.pu1, this.serviceSelectInModal.uom1];
      this.preServiceData[this.preServiceData.length-1].serviceid = this.serviceSelectInModal;
      this.preServiceData[this.preServiceData.length-1].selected = true;

     this.preServiceData[this.preServiceData.length-1].discounted_price_per_unit = this.serviceSelectInModal.pu1.toFixed(2);
     this.preServiceData[this.preServiceData.length-1].id = this.preServiceData.length;
     this.vendoreService_toggler.push(service.serviceid);
      
    },
   setDates(){

        this.preServiceData.map((value,index)=>{
         this.preServiceData[index].dates = this.allService_dates;
         this.setQuantity(index);
        });
     },
    setQuantity(i){

      if(/day|Day/.test(this.preServiceData[i].uom)){

        var dates = this.preServiceData[i].dates;
        var days = this.$moment(dates.end).diff(this.$moment(dates.start), "days");
        var update = this.preServiceData[i];
        update.quantity = days + 1;

        Vue.set(this.preServiceData, i, update);

      }
    },

    gsttotal() {
       this.tax =(this.subtotal * (this.gst / 100)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
       this.totalinvoice = Number(this.subtotal) + Number(this.subtotal * (this.gst/100));
    
    },
    taxtotal() {

      if(this.tax !=0 && this.totalinvoice != 0){
        
      this.gst= ((this.tax/this.subtotal)*100);
     
      }else{
        this.gst = 5;
        
      }
       
    },
    handleBlur() {
      this.tax = (this.tax).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    },

  


Discount(service,discountedPrice,pu){

service.discount = 100*(1-(discountedPrice/pu));
return service.discount;
},

discounted(service,discount,pu){

  // pu- price per unit
 // d is discount;
if(discount <= 100 && discount >=0){

service.discounted_price_per_unit = pu*(1-discount/100);
 return service.discounted_price_per_unit;

}else{
service.discounted_price_per_unit = NaN;
return service.discounted_price_per_unit;

}
 
},


  
    updateEditQuantity(service){
      var index = this.serviceData.findIndex(x=>x.vsid == service.vsid)
      console.log(service, index)

      if(/day|DAY|Day/.test(this.serviceData[index].uom)){
        var dates = this.serviceData[index].dates;
        var days = this.$moment(dates.end).diff(this.$moment(dates.start), "days");

        var update = this.serviceData[index];
        update.quantity = days + 1;

        Vue.set(this.serviceData, index, update);
        //this.serviceData[index].quantity = days + 1;
      }
    },
    updateMergedEditQuantity(service, mergeIndex, serviceIndex){
      var index = this.serviceData.findIndex(x=>x.vsid == service.vsid)
      console.log(index, service)

      if(/day|DAY|Day/.test(this.merged[mergeIndex][serviceIndex].uom)){
        var dates = this.merged[mergeIndex][serviceIndex].dates;
        var days = this.$moment(dates.end).diff(this.$moment(dates.start), "days");
        var update = this.serviceData[index];
        update.quantity = days + 1;
        //Vue.set(this.serviceData, index, update);
      }
    },
    async importPreset() {
      this.preServiceData = [];
      this.disableImport = true;
      this.preset.sids = JSON.parse(this.preset.sids);
      var vwrefIndex = this.uwiData.findIndex(x=>x.uwi == this.preset.sids[0].uwi)
      this.vwref = this.uwiData[vwrefIndex];

      this.gst = this.preset.tax_percentage;
      this.comments = this.preset.vendor_note
      this.quickpay = this.preset.discount;
      this.days = this.preset.sids[0].endtday;

      for (var i = 0; i < this.preset.sids.length; i++) {
        var match = this.vendorServices.filter(y=>y.serviceid == this.preset.sids[i].serviceid)

        var service = {
          discounted_price_per_unit: this.preset.sids[i].pu*(1-(this.preset.sids[i].discount/100)),
          discount: this.preset.sids[i].discount,
          percentage: this.preset.sids[i].percentage,
          pu: this.preset.sids[i].pu,
          type: this.preset.sids[i].type,
          name: this.preset.sids[i].name,
          quantity: this.preset.sids[i].quantity,
          serviceid: match[0],
          dates: {"start": this.$moment(this.preset.sids[i].start_date), "end": this.$moment(this.preset.sids[i].end_date)},
          total: this.preset.sids[i].total,
          uom: [this.preset.sids[i].pu, this.preset.sids[i].uom],
        }
        if(this.changeDates == 1){
          service['dates'] = {"start": this.$moment(this.preset_date.start), "end": this.$moment(this.preset_date.end)};
        }

        const vsid = await this.importPreService(service)

        for (var x = 0; x < this.preset.sids[i].afes.length; x++) {
          var afe = {
            service_ccid: this.preset.sids[i].afes[x].service_ccid,
            percentage: this.preset.sids[i].afes[x].percentage,
            vsid: vsid,
            service: match[0].name,
            afe: this.preset.sids[i].afes[x].afenum,
            id: match[0].serviceid,
            percentage: this.preset.sids[i].afes[x].percentage,
            ccone_code: this.preset.sids[i].afes[x].ccone,
            cctwo_code: this.preset.sids[i].afes[x].cctwo,
            ccthree_code: this.preset.sids[i].afes[x].ccthree,
          }
          this.importAfeToInvoice(afe);

        }
      }
      this.gsttotal();
      this.$refs['import-modal'].hide()
    },
    importPreService(service, i) {
      if(service.serviceid && service.uom){
        let data = {
          serviceid: service.serviceid.serviceid,
          start_date:  this.$moment(service.dates.start).format('YYYY-MM-DD'),
          end_date:  this.$moment(service.dates.end).format('YYYY-MM-DD'),
          // discounted_price_per_unit: service.discounted_price_per_unit,
          discount:  service.discount,
          projectid: this.projectid,
          quantity:  service.quantity,
          uom:  service.uom[1],
          pu: service.uom[0],
          currency:  service.serviceid.currency,
        }

        if(this.fake_vendor){
          data.fake_vid = this.fake_vendor.vid
        }

        console.log(data)
        return this.$axios.post('/vendor/create/service', data)
        .then(response => {
          var total = this.formatPrice((service.quantity * service.uom[0]) - ((service.discount/100) * service.quantity * service.uom[0]))
        
          var uom = [service.uom[0],service.uom[1]];
          var number = 0;
          if(this.serviceData.length != 0){
            number = this.serviceData[this.serviceData.length-1].number + 1;
          }

          console.log(service.serviceid);
    

          this.serviceData.push({
            vsid: response.data.vsid,
            id: service.serviceid.serviceid,
            serviceid: service.serviceid,
            name: service.serviceid.name,
            type: service.serviceid.type,
            dates:  {"start": service.dates.start.toDate(), "end": service.dates.end.toDate()},
            discounted_price_per_unit:service.uom[0]*(1-(service.discount/100)),
            discount:  service.discount,
            percentage:  0,
            quantity:  service.quantity,
            uom:  uom,
            pu: service.uom[0],
            total: total,
          })
          if(i){
            Vue.delete(this.preServiceData, i);
          }
          var vsid = response.data.vsid
         
          return vsid
        })
        .catch(error => {
        })
      }
    },
    importAfeToInvoice (afe) {
      let data = {
        id: afe.vsid,
        projectid: this.projectid,
        service_ccid: afe.service_ccid,
        percentage: afe.percentage,
      }

      if(this.fake_vendor){
        data.fake_vid = this.fake_vendor.vid
      }

      console.log(afe)
      this.$axios.post('/vendor/create/service_ap', data)
      .then(response => {
        this.afes.push({
          service: afe.service,
          afenum: afe.afe,
          id: afe.id,
          percentage: afe.percentage,
          apid: response.data.apid,
          service_ccid: afe.service_ccid,
          vsid: afe.vsid,
          ccone_code: afe.ccone_code,
          cctwo_code: afe.cctwo_code,
          ccthree_code: afe.ccthree_code,
        })
      })
      .catch(error => {
        this.$toasted.show('AFE Duplicate', {type: 'error', duration: '3000'})
      })
    },
    showImportModal() {
      this.$refs['import-modal'].show()
      this.disableImport = false;
    },
    showServiceModal(i, type,flag) {
     
      if(flag == 'flag'){
      
      this.serviceModalIndex = i;
      this.$refs['service-modal'].show();
      
      }
    },
    
    editData (line) {

      if(this.beforeEditCacheCatch == 1 || this.beforeEditAfeCacheCatch == 1){
        this.$toasted.show('Please save edit first', {type: 'error', duration: '3000'})
        return
      }

      this.beforeEditCache = Object.assign({}, line);
      this.editedLine = line
      this.beforeEditCacheCatch = JSON.parse(JSON.stringify(1));
    },
    cancelEdit (index) {
      this.serviceData[this.beforeEditCacheIndex] = this.beforeEditCache
    },
    cancelAfe: function () {
      this.beforeEditAfeCacheCatch = 0;
      this.addToInvoice = 1;
      this.addAfeButtons = 1;
      this.addAfeShow = 0;
      this.serviceChainData = [];
    },
    async submitInvoice(draft) {
 
    if(this.vwref === 1){
        this.$toasted.show('Please select well', {type: 'error', duration: '3000'})
        return
      }
      if(this.serviceData.length === 0){
        this.$toasted.show('Please add services', {type: 'error', duration: '3000'})
        return
      }
      if(this.beforeEditCacheCatch == 1){
        this.$toasted.show('Please save service', {type: 'error', duration: '3000'})
        return
      }

      var question = "Are you sure you want to submit this invoice?";

      if(draft == 1){
        question = "Are you sure you want to save as draft?"
      }

      if(confirm(question)){
        if(draft == 0 && this.fake_vendor){
          console.log('fake and submit')
          for (var i = 0; i < this.serviceData.length; i++) {
            var temp = this.afes.filter(x=>x.vsid == this.serviceData[i].vsid);
        
            if(temp.length == 0){
              this.$toasted.show('AFE Table Not Complete. ' + this.serviceData[i].name + ' does not have AFE assigned.', {type: 'error', duration: '3000'})
              return
            }
          }
        }

        var qp = 0;
        if(this.quickpay > 0){
          qp = 1;
          if(this.days === ""){
            this.$toasted.show('Please add net days', {type: 'error', duration: '3000'})
            return
          }
        }
        var endtday = this.$moment(new Date(), "YYYY-MM-DD").add(this.days, 'days');
        var vsIds = [];

        var arrayLength = this.serviceData.length;
     
        for (var i = 0; i < arrayLength; i++) {
          vsIds.push(this.serviceData[i].vsid);
        }

        var comments = this.usersName + " ($" + this.formatPrice(this.totalinvoice) + ")";
        if(this.comments != ""){
          comments += ": " + this.comments
        }

        let data = {
          services: vsIds,
          draft: draft,
          companyid: this.companyId,
          projectid: this.projectid,
          tax_percentage: this.gst,
          vwref: this.vwref.uwi,
          vendor_note: comments,
          quickpay_total: this.quickpaysubtotal + this.qpgstsubtotal,
          qpgstsubtotal: this.qpgstsubtotal,
          discount: this.quickpay,
          quickpay: qp,
          endtday: endtday.format("YYYY-MM-DD"),
          field_ticket_number: this.fieldTicketNumber || null,
          vendor_invoice_number: this.vendorInvoiceNumber || null,
          service_order_number: this.serviceOrderNumber || null,
          program_number: this.programNumber || null,
          treatment_type: this.treatmentType || null,
          vendor_invoice_date: this.vendorInvoiceDate ? this.$moment(this.vendorInvoiceDate).toISOString() : null
        }
        console.log(data,'data');

        if(this.fake_vendor){
          data.fake_vid = this.fake_vendor.vid;
          data.force_inv_passed = 6;
          data.direct = this.directRegular
        }

        if(!this.fake_vendor){

          this.$axios.post('/vendor/create/invoice', data)
          .then(response => {
            console.log(response.data);
            this.$toasted.show('Invoice Created Successfully', {type: 'success', duration: '3000'})
            this.invoiceError = false;

            this.invoiceid = response.data.invoiceid;

            var files = this.$refs.myVueDropzone.getQueuedFiles();
            console.log(files);
            if(files.length == 0){
              this.$router.push('/vendor')
            }
          })
          .catch(error => {
            this.invoiceError = true;
          })
        } else {
          this.$axios.post('/vendor/create/invoice/fake', data)
          .then(response => {
            console.log(response.data);
            this.$toasted.show('Invoice Created Successfully', {type: 'success', duration: '3000'})
            this.invoiceError = false;

            this.invoiceid = response.data.invoiceid;
            var files = this.$refs.myVueDropzone.getQueuedFiles();
            console.log(files);

            if(files.length == 0){
              this.$router.push('/accountant/view-invoices')
            }

          })
          .catch(error => {
            this.invoiceError = true;
          })
        }
      }
    },
    
    addPreService(service, i) {
      
  
      if(service.uom){
        if(service.serviceid){
          let data = {
            serviceid: service.serviceid.serviceid,
            start_date:  this.$moment(service.dates.start).format('YYYY-MM-DD'),
            end_date:  this.$moment(service.dates.end).format('YYYY-MM-DD'),
            // discounted_price_per_unit: service.discounted_price_per_unit,
            discount:  service.discount,
            projectid: this.projectid,
            quantity:  service.quantity,
            uom:  service.uom[1],
            pu: service.uom[0],
            currency:  service.serviceid.currency,
          }


          if(this.fake_vendor){
            data.fake_vid = this.fake_vendor.vid
          }

          var quantity = service.quantity;
          console.log(data)
          this.$axios.post('/vendor/create/service', data)
          .then(response => {
            var total = this.formatPrice((service.quantity * service.uom[0]) - ((service.discount/100) * service.quantity * service.uom[0]))
      
            
            var uom = [service.uom[0],service.uom[1]];
            var number = 0;
            if(this.serviceData.length != 0){
              number = this.serviceData[this.serviceData.length-1].number + 1;
            }

            console.log(service);

            this.serviceData.push({
              vsid: response.data.vsid,
              id: service.serviceid.serviceid,
              name: service.serviceid.name,
              type: service.serviceid.type,
              dates: service.dates,
              serviceid: service.serviceid,
              dates:  service.dates,
              discounted_price_per_unit: service.uom[0]*(1-(service.discount/100)),
              discount:  service.discount,
              percentage:  0,
              quantity:  quantity,
              uom:  uom,
              pu: service.uom[0],
              total: total,
            })

            this.gsttotal();
  let index1 = this.vendoreService_toggler.findIndex(services=> services == this.preServiceData[i].serviceid.serviceid);
  this.vendoreService_toggler.splice(index1,1);
  Vue.delete(this.preServiceData, i);

})
          .catch(error => {
          })
        }
      }
    },
    editService(service, i) {
      if(service.serviceid){
        this.lineLoading = true;

        let data = {
          serviceid: service.serviceid.serviceid,
          start_date:  this.$moment(service.dates.start).format('YYYY-MM-DD'),
          end_date:  this.$moment(service.dates.end).format('YYYY-MM-DD'),
          // discounted_price_per_unit:service.discounted_price_per_unit,
          discount:  service.discount,
          projectid: this.projectid,
          type: service.type,
          quantity:  service.quantity,
          uom:  service.uom[1],
          pu: service.uom[0],
          currency:  service.serviceid.currency,
        }


        if(this.fake_vendor){
          data.fake_vid = this.fake_vendor.vid
        }


        this.$axios.post('/vendor/create/service', data)
        .then(response => {
          var total = this.formatPrice((service.quantity * service.uom[0]) - ((service.discount/100) * service.quantity * service.uom[0]))

          var uom = [service.uom[0],service.uom[1]];
          var number = 0;
          if(this.serviceData.length != 0){
            number = this.serviceData[this.serviceData.length-1].number + 1;
          }
          this.vsid = response.data.vsid
          this.removeService({
            vsid: service.vsid,
          }, 1);

          var index = this.serviceData.findIndex(x=>x.vsid == service.vsid)

          Vue.set(this.serviceData, index, {
            vsid: response.data.vsid,
            id: service.serviceid.serviceid,
            serviceid: service.serviceid,
            dates:  service.dates,
            discounted_price_per_unit:service.uom[0]*(1-(service.discount/100)),
            discount:  service.discount,
            percentage:  0,
            quantity:  service.quantity,
            uom:  uom,
            pu: service.uom[0],
            total: total,
            name: service.serviceid.name,
            type: service.serviceid.type,
          });

          this.gsttotal();
          // this.taxtotal();
          this.editedLine = [];
          this.beforeEditCacheCatch = 0; 
          this.lineLoading = false;
        })
        .catch(error => {
          console.log(error)
        })
      }
    },
    async addAllLines() {

      this.loading = true;
      var count = this.preServiceData.length;
        if(count > 0){

        for (var i = 0; i < this.preServiceData.length; i++) {
        var service = this.preServiceData[i];

        console.log(service)


          let data = {
              serviceid: service.serviceid.serviceid,
              start_date:  this.$moment(service.dates.start).format('YYYY-MM-DD'),
              end_date:  this.$moment(service.dates.end).format('YYYY-MM-DD'),   
              discount:  service.discount,
              projectid: this.projectid,
              quantity:  service.quantity,
              uom:  service.uom[1],
              pu: service.uom[0],
              currency:  service.serviceid.currency,
          }

          if(this.fake_vendor){
            data.fake_vid = this.fake_vendor.vid
          }

          const serviceAwait = await this.$axios.post('/vendor/create/service', data)
          .then(response => {
            var total = this.formatPrice((
              service.quantity * service.uom[0]) - ((service.discount/100) * service.quantity * service.uom[0]))
              if(service){
                var uom = [service.uom[0],service.uom[1]];

                var uom = [service.uom[0],service.uom[1]];
                var number = 0;
                if(this.serviceData.length != 0){
                  number = this.serviceData[this.serviceData.length-1].number + 1;
                }
                console.log(service.serviceid.name);
                // this.gst = 5;
                //Vue.delete(this.preServiceData, i)

                this.serviceData.push({
                  vsid: response.data.vsid,
                  id: service.serviceid.serviceid,
                  name: service.serviceid.name,
                  serviceid: service.serviceid,
                  type: service.serviceid.type,
                  dates:  service.dates,
                  discounted_price_per_unit:service.uom[0]*(1-(service.discount/100)),
                  discount:  service.discount,
                  percentage:  0,
                  quantity:  service.quantity,
                  uom:  uom,
                  pu: service.uom[0],
                  total: total,
                });
                 this.gsttotal();
                 this.vendoreService_toggler.length = 0;

              }
          })
          .catch(error => {
          })
        }
      //}
        this.preServiceData = [];
      }
      this.loading = false;
    },
    deletePreLineServices(i){
     let index1 = this.vendoreService_toggler.findIndex(services=> services == this.preServiceData[i].serviceid.serviceid);
     this.vendoreService_toggler.splice(index1,1);
      Vue.delete(this.preServiceData, i);
    },
    removeService (service, edit) {
      console.log(service);
      var changed = 1;

      var index = this.serviceData.findIndex(x=>x.vsid == service.vsid)

      if(this.serviceData[index]){
        var vsid = this.serviceData[index].vsid
        var afesToRemove = this.afes.filter(x=>x.vsid === vsid)

        for (var i = 0; i < afesToRemove.length; i++) {
          if(this.beforeEditCache.serviceid && this.serviceData[index].serviceid.serviceid == this.beforeEditCache.serviceid.serviceid){
            changed = 0;
            console.log('')
            this.removeAfe(afesToRemove, changed)
          }
          else{
            changed = 1;
            this.removeAfe(afesToRemove, changed)
          }
        }
      }
      if(edit != 1){
        this.serviceData.splice(index, 1);
      }
       if(this.serviceData.length == 0){
         this.gst = 5;
       }
       this.gsttotal();
    },
    addNewAfe (id, vsid, servicename) {
      this.beforeEditAfeCacheCatch = 1;
      this.cc1 = [];
      this.cc2 = [];
      this.cc3 = [];
      this.afe = [];
      this.vsid = vsid;
      this.addToInvoice = 0;
      this.addAfeButtons = 0;
      this.percent = 100;
      this.servicenameSort = servicename;

      if(!this.fake_vendor){
        this.$axios.get("/vendor/get/service_chains/" + this.companyId + "/" + this.projectid + "/" + id)
        .then(response => {
          if(response.data.result.length > 0){
            var result = this.lodash.groupBy(response.data.result,'afenum')
            this.serviceChainData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
            this.afe = this.serviceChainData[0];

            //cc1
            var temp = this.afe.items;
            temp = this.lodash.groupBy(temp, 'ccone_code');
            this.cc1Data = Object.keys(temp).map(key => ({ ccone_code: key, items: temp[key] }));
            this.cc1 = this.cc1Data[0];

            //cc2
            var temp2 = this.cc1.items;
            temp2 = this.lodash.groupBy(temp2, 'cctwo_code');
            this.cc2Data = Object.keys(temp2).map(key => ({ cctwo_code: key, items: temp2[key] }));
            this.cc2 = this.cc2Data[0];

            //cc3
            var temp3 = this.cc2.items.filter(o => !o.ccthree_code.includes('---'));
            temp3 = this.lodash.groupBy(temp3, 'ccthree_code');
            this.cc3Data = Object.keys(temp3).map(key => ({ ccthree_code: key, items: temp3[key] }));
            this.cc3 = this.cc3Data[0];

            this.addAfeShow = 1;
          }
          if(response.data.result.length === 0){
            this.addAfeShow = 0;
            this.beforeEditCacheCatch = 0;
            this.beforeEditAfeCacheCatch = 0;
            this.addToInvoice = 1;
            this.addAfeButtons = 1;
            this.$toasted.show('No AFES', {type: 'error', duration: '3000'})
          }
        })
        .catch(error => {
          this.addAfeShow = 0;
          this.beforeEditCacheCatch = 0;
          this.beforeEditAfeCacheCatch = 0;
          this.addToInvoice = 1;
          this.addAfeButtons = 1;
          this.$toasted.show('No AFES', {type: 'error', duration: '3000'})
        })
      } else {
       this.$axios.get("/vendor/get/service_chains/" + this.companyId + "/" + this.projectid + "/" + id + "/" + this.fake_vendor.vid)
        .then(response => {
          if(response.data.result.length > 0){
            var result = this.lodash.groupBy(response.data.result,'afenum')
            this.serviceChainData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
            this.afe = this.serviceChainData[0];

            //cc1
            var temp = this.afe.items;
            temp = this.lodash.groupBy(temp, 'ccone_code');
            this.cc1Data = Object.keys(temp).map(key => ({ ccone_code: key, items: temp[key] }));
            this.cc1 = this.cc1Data[0];

            //cc2
            var temp2 = this.cc1.items;
            temp2 = this.lodash.groupBy(temp2, 'cctwo_code');
            this.cc2Data = Object.keys(temp2).map(key => ({ cctwo_code: key, items: temp2[key] }));
            this.cc2 = this.cc2Data[0];

            //cc3
            var temp3 = this.cc2.items.filter(o => !o.ccthree_code.includes('---'));
            temp3 = this.lodash.groupBy(temp3, 'ccthree_code');
            this.cc3Data = Object.keys(temp3).map(key => ({ ccthree_code: key, items: temp3[key] }));
            this.cc3 = this.cc3Data[0];

            this.addAfeShow = 1;
          }
          if(response.data.result.length === 0){
            this.addAfeShow = 0;
            this.beforeEditCacheCatch = 0;
            this.beforeEditAfeCacheCatch = 0;
            this.addToInvoice = 1;
            this.addAfeButtons = 1;
            this.$toasted.show('No AFES', {type: 'error', duration: '3000'})
          }
        })
        .catch(error => {
          this.addAfeShow = 0;
          this.beforeEditCacheCatch = 0;
          this.beforeEditAfeCacheCatch = 0;
          this.addToInvoice = 1;
          this.addAfeButtons = 1;
          this.$toasted.show('No AFES', {type: 'error', duration: '3000'})
        })
      }

    },
    addAfeToInvoice: function (afe) {
      if(this.percent > 100 || this.percent <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }
      var cc_num = [];
      if(this.cc3 !== undefined && this.cc3 !== null){
        cc_num = this.cc3.items[0];
      }
      else {
        cc_num = this.cc2.items[0];
      }
      let data = {
        id: this.vsid,
        projectid: this.projectid,
        service_ccid: cc_num.service_ccid,
        percentage: this.percent,
      }

      if(this.fake_vendor){
        data.fake_vid = this.fake_vendor.vid
      }

      this.$axios.post('/vendor/create/service_ap', data)
      .then(response => {
        this.afes.push({
          service: this.servicenameSort,
          afe: cc_num.afenum,
          id: cc_num.serviceid,
          percentage: this.percent,
          apid: response.data.apid,
          service_ccid: cc_num.service_ccid,
          vsid: this.vsid,
          ccone_code: cc_num.ccone_code,
          cctwo_code: cc_num.cctwo_code,
          ccthree_code: cc_num.ccthree_code,
        })

        this.afesSorted = this.lodash.chain(this.afes)
        .groupBy('service')
        .map((service, code) => ({ service, code }))
        .sortBy('code')
        .value();


        this.addAfeShow = 0;
        this.afe = [];
        this.addAfeButtons = 1;
        this.beforeEditAfeCacheCatch = 0;
      })
      .catch(error => {

        this.$toasted.show('AFE Duplicate', {type: 'error', duration: '3000'})
      })
    },
    removeAfe (afesToRemove, changed) {
      console.log(afesToRemove)
      for (var i = 0; i < afesToRemove.length; i++) {
        var afe = afesToRemove[i];
        let data = {
          id: afe.apid,
          projectid: this.projectid,
        }
        console.log(afe.vsid, 'delete vsid')

        if(this.fake_vendor){
          data.fake_vid = this.fake_vendor.vid
        }

        this.$axios.post('/vendor/delete/service_ap', data)
        .then(response => {})
        .then(response => {
          var find = this.afes.findIndex(function(item, i){
            return item.apid === afe.apid
          });

          if(changed == 0){
            console.log('editing service afes');
            let data = {
              id: this.vsid,
              projectid: this.projectid,
              service_ccid: afe.service_ccid,
              percentage: afe.percentage,
            }

            if(this.fake_vendor){
              data.fake_vid = this.fake_vendor.vid
            }

            console.log(data)
            this.$axios.post('/vendor/create/service_ap', data)
            .then(response => {
              console.log(this.vsid)
              Vue.set(this.afes, find, {
                afenum: afe.afenum,
                percentage: afe.percentage,
                apid: response.data.apid,
                service_ccid: afe.service_ccid,
                vsid: this.vsid,
                ccone_code: afe.ccone_code,
                cctwo_code: afe.cctwo_code,
                ccthree_code: afe.ccthree_code,
              })
            })
          }
          else {
            Vue.delete(this.afes, find);
          }
        })
        .catch(error => {
          console.log(error)
          this.$toasted.show('AFE Duplicate', {type: 'error', duration: '3000'})
        })
      }
    },
    async get_projects(){
      this.vendorServices = [];
      this.loadingCompany = true;
      if(this.fake_vendor){
       
        await this.$axios.get('/vendor/get/allowed_projects/' + this.companyId + '/' + this.fake_vendor.vid)
        .then(response => {
          if (response.status === 200) {
            this.projectData = response.data.result;
           this.price_per_unit = response.data.result[0].pu1.toFixed(2);
            if(this.projectData.length > 0){
              this.getUwis();
            }
            this.loadingCompany = false;
          }
        })
      } else {
     
        await this.$axios.get('/vendor/get/allowed_projects/' + this.companyId)
        .then(response => {
          if (response.status === 200) {
            this.projectData = response.data.result;
          
           this.price_per_unit = response.data.result[0].pu1.toFixed(2);

            if(this.projectData.length > 0){
              this.getUwis();
            }
            this.loadingCompany = false;
          }
        })
      }

    },
    async get_services(){
      this.selectedProject = this.projectData.filter(x=>x.projectid == this.projectid);
      if(!this.fake_vendor) {
        await this.$axios.get('/vendor/get/allowed_services/' + this.companyId + '/' + this.projectid)
        .then(response => {
          if (response.status === 200) {
            this.vendorServices = response.data.result;
            if(this.vendorServices.length > 0){
              this.preServiceData = [];
            }
          }
        })
        .catch(error => {

        })
      }
      else {
       
         await this.$axios.get('/vendor/get/allowed_services/' + this.companyId + '/' + this.projectid + '/' + this.fake_vendor.vid)
        .then(response => {
          if (response.status === 200) {
            this.vendorServices = response.data.result;
            if(this.vendorServices.length > 0){
              this.preServiceData = [];
            }
          }
        })
        .catch(error => {

        })
      }

    },
    selectWell() {
      this.projectid = this.vwref.projectid;
      this.projectname = this.vwref.projectname;
      this.get_services();
      this.get_presets();
    },
    async getUwis(services) {
      if(!this.fake_vendor){
          this.$axios.get("/vendor/get/service_chains/" + this.companyId)
          .then(response => {
            this.uwiData = response.data.result.filter(x => this.projectData.some(y => x.projectid == y.projectid));
              this.vwref = this.uwiData[0];
              this.projectid = this.vwref.projectid;
              this.projectname = this.vwref.projectname;
               this.get_services();
               this.get_presets();
          })
          .catch(error => {
          })
      } else {
        for (var i = 0; i < services.length; i++) {
          this.$axios.get("/vendor/get/service_chains/" + this.companyId + "/" + this.projectid + "/" + services[i].serviceid + "/" + this.fake_vendor.vid)
          .then(response => {
            this.uwiData = this.uwiData.concat(response.data.result);
            if(i === services.length){
              var temp = this.lodash.uniqBy(this.uwiData, 'uwi');
              this.uwiData = temp.filter(x=>x.projectid === this.projectid);
            }
          })
          .catch(error => {
          })
        }
      }
    },
    get_presets() {
      this.$axios.get("/vendor/get/invoice/templates/" + this.companyId + "/" + this.projectid)
      .then(response => {
        this.presetData = response.data.result;
        // console.log(this.presetData,"preset data");
      })
      .catch(error => {
      })
    },
    get_company(){
      this.$http.get('/get/company/avatar/' + this.companyId ).then(response => {
          if(response.data.avatar) {
            this.avatar = response.data.avatar;
          }
      }).catch(error => {
            //console.log("Error getting avatar " + error);
      })
      if(this.fake_vendor){
         this.$axios.get('/vendor/get/company_address/v2/' + this.companyId + '/' + this.fake_vendor.vid)
        .then(response => {
          this.companyInfo = response.data;
        })
        .catch(error => {
        })
      } else  {
        this.$axios.get('/vendor/get/company_address/' + this.companyId)
        .then(response => {
          this.companyInfo = response.data;
        })
        .catch(error => {
        })
      }
    },
    removeAll(){
      this.loading = true;
      this.afes.forEach(v=> {
      let data = { id: v.apid, service_ccid: v.service_ccid, projectid: this.projectid }
        if(this.fake_vendor){
          data.fake_vid = this.fake_vendor.vid
        }
        this.$axios.post('/vendor/delete/service_ap', data)
        .then(response => {
          this.afes = [];
          this.createdServiceLine = [];
          this.createdUnassignedServiceLine = [];
          this.disableEdits = 0;
        })
        .catch(error => {})
      });
      this.loading = false;
    },
    async editAfeLine (afe, serviceIndex, afeIndex, afes, service) {

      if(this.disableEdits == 1 || this.beforeEditCacheCatch == 1 || this.beforeEditAfeCacheCatch == 1){
        this.$toasted.show('Please save edit first.', {type: 'error', duration: '3000'})
        return
      }
      if(service.length > 1){
        this.$toasted.show('Cannot edit grouped cost code chains.', {type: 'error', duration: '3000'})
        return
      }
      this.editAfeChainLine = {
        afe_num: afe.afenum,
        ccone_code: afe.ccone_code,
        cctwo_code: afe.cctwo_code,
        ccthree_code: afe.ccthree_code,
        percentage: afe.percentage,
        
      };
      this.editAfe = 1;

      this.editedAfeLine = afe;

     if(!this.fake_vendor){

       this.$axios.get("/vendor/get/service_chains/" + this.companyId + "/" + this.projectid + "/" + service[0].id + "/" + 1)
        .then(response => {
          if(response.data.result.length > 0){
            this.percentage = afe.percentage;
             this.disableEdits = 1;
            var result = this.lodash.groupBy(response.data.result, 'afenum');
            this.serviceChainData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
            var currentAfe = this.serviceChainData.findIndex(x=>x.afenum == afe.afenum)
            this.merged[serviceIndex][0].afes[afeIndex].afenum = this.serviceChainData[currentAfe];
            this.editAfe = 1;
          }
          else {
            this.createdServiceLine = 0;
            this.$toasted.show('No AFES Found', {type: 'error', duration: '3000'})
            return
          }
          this.cc1Data = response.data.cost_code_chain_data;
          var cc1Index = this.cc1Data.findIndex(x=>x.costcode2 == afe.cctwo_code && x.costcode3 == afe.ccthree_code)
          this.CC_data = this.cc1Data[cc1Index];
          this.editAfe = 1;

        })
        .catch(err=> {});   
      } else {
          const makeAfe = await this.$axios.get("/vendor/get/service_chains/" + this.companyId + "/" + this.projectid + "/" + service[0].id + "/" + this.fake_vendor.vid)
        .then(response => {
          if(response.data.result.length > 0){
            this.percentage = afe.percentage;
            this.disableEdits = 1;

            var result = this.lodash.groupBy(response.data.result, 'afenum')
            this.serviceChainData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
            var currentAfe = this.serviceChainData.findIndex(x=>x.afenum == afe.afenum)
            this.merged[serviceIndex][0].afes[afeIndex].afenum = this.serviceChainData[currentAfe];
            this.editAfe = 1;
            return this.serviceChainData[currentAfe];
          }
          else {
            this.editedAfeLine = [];
            this.$toasted.show('No AFES Found', {type: 'error', duration: '3000'})
            return
          }
        })
        .then(response => {
          //cc1
          if(response.items.length > 0){
            var afeData = this.lodash.groupBy(response.items, 'ccone_code');
            this.cc1Data = Object.keys(afeData).map(key => ({ ccone_code: key, items: afeData[key] }));
            var cc1Index = this.cc1Data.findIndex(x=>x.ccone_code == afe.ccone_code)
            this.merged[serviceIndex][0].afes[afeIndex].ccone_code = this.cc1Data[cc1Index];
            this.editAfe = 1;

            return this.cc1Data[cc1Index]
          }
        }).then(response => {
          // //cc2
          if(response.items.length > 0){
            var cc1 = this.lodash.groupBy(response.items, 'cctwo_code');
            this.cc2Data = Object.keys(cc1).map(key => ({ cctwo_code: key, items: cc1[key] }));
            var cc2Index = this.cc2Data.findIndex(x=>x.cctwo_code == afe.cctwo_code)
            this.merged[serviceIndex][0].afes[afeIndex].cctwo_code = this.cc2Data[cc2Index];
            this.editAfe = 1;

            return this.cc2Data[cc2Index];
          }
        }).then(response => {
          //cc3
          if(response.items.length > 0){
            var cc3Temp = response.items.filter(o => !o.ccthree_code.includes('---'));
            var cc3Group = this.lodash.groupBy(cc3Temp, 'ccthree_code');
            this.cc3Data = Object.keys(cc3Group).map(key => ({ ccthree_code: key, items: cc3Group[key] }));
            var cc3Index = this.cc3Data.findIndex(x=>x.ccthree_code == afe.ccthree_code)
            this.merged[serviceIndex][0].afes[afeIndex].ccthree_code = this.cc3Data[cc3Index];
            this.editAfe = 1;
          }
        }).then(response => {

        }).catch(error => {
          console.log(error)
        })
      }
    },
    async editAddAfeToInvoice(line, service, serviceIndex, afeIndex, afes){
      let cc_num = this.CC_data;
      if(this.percentage > 100 || this.percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }
        if(service[0].afes.length > 0) {
        for(let [i, v] of Object.entries(service[0].afes)) {
          if(i != afeIndex) {
          if(v.afenum == line.afenum.afenum && v.ccone_code == cc_num.costcode1 && v.cctwo_code == cc_num.costcode2  && v.ccthree_code == cc_num.costcode3) {
              this.$toasted.show('Duplicates Chain', {type: 'error', duration: '3000'})
                return 
            }
          }
        }
      }
      const  { ccthree_code, ccone_code, afe_num, cctwo_code } = this.editAfeChainLine;
      let find = this.afes.findIndex(x => ( x.vsid == service[0].vsid && x.ccthree_code == ccthree_code && x.ccone_code == ccone_code && x.cctwo_code == cctwo_code && x.afenum == afe_num));
      let deleteAfeData = {
            id: this.afes[find].apid,
            service_ccid: this.afes[find].service_ccid,
            projectid: this.projectid
      }
      const deleteAfe = await this.$axios.post('/vendor/delete/service_ap', JSON.stringify(deleteAfeData));
      this.addAfeUnassignedLine(service[0], line, find);
    },
    addNewUnassignedAfeLine(afe, service){

     if(!this.fake_vendor){

       this.$axios.get("/vendor/get/service_chains/" + this.companyId + "/" + this.projectid + "/" + service.id + "/" + 1)
        .then(response => {
          if(response.data.result.length > 0){
            this.createdUnassignedServiceLine = afe;
            this.percentage = 100;
            var result = this.lodash.groupBy(response.data.result, 'afenum');
            this.createAfeData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
            this.createAfenum = this.createAfeData[0];
            this.createAfe = 1;
            this.disableEdits = 1;
            this.createAfeData[0];
          }
          else {
            this.createdServiceLine = 0;
            this.$toasted.show('No AFES Found', {type: 'error', duration: '3000'})
            return
          }
          this.cc1CreateData = response.data.cost_code_chain_data;
          this.CC_data = this.cc1CreateData[0];
          this.createAfe = 1;

        })
        .catch(err=> {});   

     }
    
    },
    addNewAfeLine(afe, service){

      if(!this.fake_vendor){
        this.$axios.get("/vendor/get/service_chains/" + this.companyId + "/" + this.projectid + "/" + service.id + "/" + 1)
        .then(response => {
          console.log(response,"-------------response for afe-1")
          if(response.data.result.length > 0){
            this.toggle_add_afe_line = true;
            this.createdServiceLine = afe;
            this.createdServiceLineItem = service;
            this.percentage = 100;
            var result = this.lodash.groupBy(response.data.result, 'afenum')
            this.createAfeData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
            this.createAfenum = this.createAfeData[0];
            this.createAfe = 1;
            this.disableEdits = 1;
          }
          else {
            this.createdServiceLine = 0;
            this.$toasted.show('No AFES Found', {type: 'error', duration: '3000'})
            return
          }
          this.cc1CreateData = response.data.cost_code_chain_data;
          this.CC_data= this.cc1CreateData[0];
          this.createAfe = 1;
        })
        .catch(error => {
          console.log(error)
        });

      } else {
        this.$axios.get("/vendor/get/service_chains/" + this.companyId + "/" + this.projectid + "/" + service.id + '/' + this.fake_vendor.vid)
        .then(response => {
          console.log(response,"------------afes chain -2")
          if(response.data.result.length > 0){
            this.createdServiceLine = afe;
            this.createdServiceLineItem = service;
            this.percentage = 100;
            var result = this.lodash.groupBy(response.data.result, 'afenum')
            this.createAfeData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
            this.createAfenum = this.createAfeData[0];
            this.createAfe = 1;
            this.disableEdits = 1;
            return this.createAfeData[0];
          }
          else {
            this.createdServiceLine = 0;
            this.$toasted.show('No AFES Found', {type: 'error', duration: '3000'})
            return
          }
        }).then(response => {
          //cc1
          var afeData = this.lodash.groupBy(response.items, 'ccone_code');
          this.cc1CreateData = Object.keys(afeData).map(key => ({ ccone_code: key, items: afeData[key] }));
          this.createCC1Code = this.cc1CreateData[0];
          this.createAfe = 1;
          return this.cc1CreateData[0]
        }).then(response => {
          //cc2
          var cc1 = this.lodash.groupBy(response.items, 'cctwo_code');
          this.cc2CreateData = Object.keys(cc1).map(key => ({ cctwo_code: key, items: cc1[key] }));
          this.createCC2Code = this.cc2CreateData[0];
          this.createAfe = 1;
          return this.cc2CreateData[0];
        }).then(response => {
          //cc3
          if(response.items.length > 0){
            var cc3Temp = response.items.filter(o => !o.ccthree_code.includes('---'));
            var cc3Group = this.lodash.groupBy(cc3Temp, 'ccthree_code');
            this.cc3CreateData = Object.keys(cc3Group).map(key => ({ ccthree_code: key, items: cc3Group[key] }));
            this.createCC3Code = this.cc3CreateData[0];
            this.createAfe = 1;
          }
        })
        .then(response => {
          this.createAfe = 0;
        }).catch(error => {
          console.log(error)
        })
      }
    },
    addAfeUnassignedLine(service, line, find){
      let data = {};
      if(this.percentage > 100 || this.percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      } 

    var cc_num = [];
    cc_num = this.CC_data;
    cc_num['afenum'] = line == undefined ? this.createAfenum.afenum : line.afenum.afenum;
     if(service.afes.length > 0) {
        service.afes.forEach(v=> {
          if(line == undefined) {
            if(v.afenum == this.createAfenum.afenum && v.ccone_code == cc_num.costcode1 && v.cctwo_code == cc_num.costcode2 && v.ccthree_code == cc_num.costcode3) {
            this.$toasted.show('Duplicates Chain', {type: 'error', duration: '3000'})
            return 
          }
        }
      })   
    }

    let service_data = this.serviceData.filter((x, i) => this.serviceData[i].vsid == service.vsid);
    let send = JSON.stringify({
        serviceids: service_data,
        projectid: this.projectid,
        pwcaid:  line == undefined ?  this.createAfenum.items[0].pwcaid : line.afenum.items[0].pwcaid,
        costcode1: cc_num.costcode1,
        costcode2: cc_num.costcode2,
        costcode3: cc_num.costcode3,
        companyid: this.companyId,
        projectType : 1,
        vid: this.vendorId
      });
    this.AddServiceToAFe(send, service, cc_num, line, find);

    if(this.fake_vendor){
        data.fake_vid = this.fake_vendor.vid
      }

    },
    AddAfe(service, data, line, find) {
      this.$axios.post('/vendor/create/service_ap', data)
      .then(response => {

      if(line !== undefined) { 
        Vue.set(this.afes, find, {
          afenum: line.afenum.afenum,
          ccone_code: data.ccone_code,
          cctwo_code: data.cctwo_code,
          ccthree_code: data.ccthree_code,
          percentage: this.percentage,
          service_ccid: data.service_ccid,
          apid: response.data.apid,
          vsid: data.id
        });
        
      } else {
        this.afes.push({
          vsid: data.id,
          afenum: this.createAfenum.afenum,
          ccone_code:  data.ccone_code,
          cctwo_code: data.cctwo_code,
          ccthree_code: data.ccthree_code,
          percentage: this.percentage,
          service_ccid: data.service_ccid,
          apid: response.data.apid
        });

      }
        this.createdUnassignedServiceLine = 0;
        this.disableEdits = 0;
      })
      .catch(error => {
        this.$toasted.show('Error Creating Afe', {type: 'error', duration: '3000'})
      })
    },
    addAfeLine(afes, line){
      if(this.percentage > 100 || this.percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }
      this.addAfeUnassignedLine(afes[0]);
    },
    AddServiceToAFe(send, service, cc_num, line, find) {
        this.$http
          .post("/wts/create/costcode_and_assign_service", send)
          .then((response) => {
            if (response.status === 200) {
              this.$toasted.show("Successful Import.", {
                type: "success",
                duration: "3000",
              });
          response.data.addedServices.forEach(v=> {    
              let data = {
                id: v.vsid,
                percentage: this.percentage,
                projectid: this.projectid,
                ccone_code: cc_num.costcode1,
                cctwo_code: cc_num.costcode2,
                ccthree_code: cc_num.costcode3,
                service_ccid : v.service_ccid
              }
              this.AddAfe( service, data, line, find);
          });
            
        }
      })
      .catch((error) => {
         this.$toasted.show("Error Adding cost Code", { type: "error", duration: "3000" });
      })
    },
    async addAfeToAllInvoiceServices(service) {
      if(this.percentage > 100 || this.percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }

      let cc_num = this.CC_data;
      cc_num['afenum'] = this.createAfenum.afenum;
      if(this.afes.filter(e => e.afenum === cc_num.afenum).length > 0){
        this.$toasted.show('AFE Duplicate For a line item', {type: 'error', duration: '3000'})
        return;
      }
     let send = JSON.stringify({
        serviceids: this.serviceData,
        projectid: this.projectid,
        pwcaid: this.createAfenum.items[0].pwcaid,
        costcode1: cc_num.costcode1,
        costcode2: cc_num.costcode2,
        costcode3: cc_num.costcode3,
        companyid: this.companyId,
        projectType : 1,
        vid: this.vendorId
      });
      await this.AddServiceToAFe(send, this.serviceData, cc_num);

      this.createdServiceLine = 0;
      this.disableEdits = 0;
      this.lineLoading = true;
      for (var i = 0; i < this.serviceData.length; i++) {
        var service = this.serviceData[i];
        const tryImportAfe = await this.tryImportAfe(service, cc_num);
      };

      this.lineLoading = false;
    },
    getChain(serviceid){
      if(!this.fake_vendor){
        return this.$axios.get("/vendor/get/service_chains/" + this.companyId + "/" + this.projectid + "/" + serviceid)
        .then(response => {
          if(response.data.result.length > 0){
            return response.data.result;
          }
        }).catch(error => {
          console.log(JSON.stringify(error))
        })
      } else {
        return this.$axios.get("/vendor/get/service_chains/" + this.companyId + "/" + this.projectid + "/" + serviceid + '/' + this.fake_vendor.vid)
        .then(response => {
          if(response.data.result.length > 0){
            return response.data.result;
          }
        }).catch(error => {
          console.log(JSON.stringify(error))
         })
      }

    },
    async tryImportAfe(service, cc_num){
      // console.log(service, cc_num);
      const getChain = await this.getChain(service.id);
      var match = [];
      if(getChain.length > 0){
        console.log(getChain)
        if(cc_num.cc_num == 3){
          match = getChain.filter(x=>x.afenum == cc_num.afenum && x.ccone_code == cc_num.ccone_code && x.cctwo_code == cc_num.cctwo_code && x.ccthree_code == cc_num.ccthree_code && x.serviceid == service.id)
          console.log(match)
        }
        else {
          match = getChain.filter(x=>x.afenum == cc_num.afenum && x.ccone_code == cc_num.ccone_code && x.cctwo_code == cc_num.cctwo_code && x.serviceid == service.id)
          console.log(match)
        };
        if(match.length > 0){
          let data = {
            id: service.vsid,
            projectid: this.projectid,
            service_ccid: match[0].service_ccid,
            percentage: this.percentage,
          }

          if(this.fake_vendor){
            data.fake_vid = this.fake_vendor.vid
          }

          this.$axios.post('/vendor/create/service_ap', data)
          .then(response => {
            this.afes.push({
              vsid: service.vsid,
              afenum: match[0].afenum,
              ccone_code: match[0].ccone_code,
              cctwo_code: match[0].cctwo_code,
              ccthree_code: match[0].ccthree_code,
              percentage: this.percentage,
              service_ccid: match[0].service_ccid,
              apid: response.data.apid,
            })
            this.disableEdits = 0;
          }).catch(error => {
            console.log(JSON.stringify(error))
          })
        }
        else {
          this.disableEdits = 0;
        }
      }
      else {
        this.disableEdits = 0;
      }
    },

    removeAllAfe: function (service) {
      this.afes.forEach((v, i) => {
        if(v.vsid == service.vsid) {
          let data = { id: v.apid, projectid: this.projectid, service_ccid: v.service_ccid }
        if(this.fake_vendor){
          data.fake_vid = this.fake_vendor.vid
        }
        this.$axios.post('/vendor/delete/service_ap', data)
        .then(() => {
          this.afes = this.afes.filter(x => x.vsid !== service.vsid);
          this.editAfe = 0;
          this.createAfe = 0;
          this.disableEdits = 0;
          this.createdServiceLine = [];
          this.createdUnassignedServiceLine = [];
        })
        .catch(() => {})
        }
     });
    },
    cancelAfe: function () {
      this.editAfe = 0;
      this.createAfe = 0;
      this.disableEdits = 0;
      this.createdServiceLine = [];
      this.createdUnassignedServiceLine = [];
    },
  },
  computed: {
    subtotal: function(){
      return this.serviceData.reduce(function(total, item){
        console.log(total + (item.quantity * item.pu) - ((item.discount/100) * item.quantity * item.pu),'subtotal');
        return total + (item.quantity * item.pu) - ((item.discount/100) * item.quantity * item.pu);
      },0);
    },
    quickpaysubtotal: function() {
      return this.subtotal - ((this.quickpay/100) * this.subtotal);
    },
    gstsubtotal: function() {

      return (Number(this.subtotal * (this.gst/100)).toFixed(6));
    },
    qpgstsubtotal: function() {
      return this.quickpaysubtotal * (this.gst/100);
    },
    qpdiscount: function() {
      return ((this.quickpay/100) * this.subtotal)
    },
    totalinvoice: function() {
      return (Number(this.subtotal) + Number(this.gstsubtotal));
    },
    qptotalinvoice: function() {
      return this.quickpaysubtotal + this.qpgstsubtotal;
    },
    unassigned: function(){
      var unassigned = [];
      for (var i = 0; i < this.serviceData.length; i++) {
        var filtered = this.afes.filter(x=>x.vsid == this.serviceData[i].vsid)
        if(filtered.length == 0) {
          unassigned.push(this.serviceData[i])
        }
      }
      return unassigned
    },
    merged: function(){
      var dict = {};
      console.log(this.serviceData,":::data");
      this.serviceData.forEach(service => {
        var filtered = this.afes.filter(x=>x.vsid == service.vsid);
        service.afes = [];
        filtered.forEach(afe => {
          service.afes.push({
            afenum: afe.afenum,
            ccone_code: afe.ccone_code,
            cctwo_code: afe.cctwo_code,
            ccthree_code: afe.ccthree_code,
            percentage: afe.percentage,
          })
        })
      })

      this.serviceData.forEach(service => {
        if(service.afes.length > 0){
          dict[JSON.stringify(service)] = []
        }
      });

      this.serviceData.forEach(service => {
        service.afes.forEach(afe => {
          dict[JSON.stringify(service)] = dict[JSON.stringify(service)].concat(afe);
        });
      })

      var dictAfe = {};

      for (var service in dict) {
        let serviceValue = dict[service]
        let key = JSON.stringify(dict[service]);
        if(!(key in dictAfe)){
          dictAfe[key] = [JSON.parse(service)];
        } else {
          dictAfe[key] = dictAfe[key].concat(JSON.parse(service));
        }
      }

      var temp = []

      for (var service in dictAfe) {
        temp.push(dictAfe[service]);
      }
      return temp;
    },
  },
  mounted: async function() {
    let token = this.$session.get('jwt');
    var decoded = jwt_decode(token);


    this.preServiceData.length = 0;
    if(!this.fake_vendor){
      await this.$axios.get('/vendor/get/profile')
      .then(response => {
        this.vendorDetails = response.data;
        this.usersName = response.data.fname + " " + response.data.lname;
      })
      this.vendorId = decoded.id
      await this.$http.get('/get/vendor/avatar/' + decoded.id ).then(response => {
      this.vendorAvatar = response.data.avatar;
      }).catch(error => {
          console.log("Error getting avatar " + error);
      })

      await this.$axios.get('/vendor/get/allowed_companies')
      .then(response => {
        this.companyData = response.data.result;
        this.companyId = this.companyData[0];
      })

    } else {
       await this.$axios.get('/vendor/get/profile/'+this.fake_vendor.vid)
      .then(response => {
        this.vendorDetails = response.data;
      })

      await this.$http.get('/get/vendor/avatar/' +this.fake_vendor.vid).then(response => {
      this.vendorAvatar = response.data.avatar;
      }).catch(error => {
          console.log("Error getting avatar " + error);
      })

      await this.$axios.get('/vendor/get/allowed_companies/'+this.fake_vendor.vid)
      .then(response => {
        this.companyData = response.data.result;
        /* this.companyId = this.companyData[0]; */
      
      })
    }


  }
}

</script>

<style>
  .vue-dropzone > .dz-preview .dz-error-message {
    opacity: 100;
    left: 5% !important;
    top: 135px;
    width: 90%;
  }
  .vue-dropzone > .dz-preview .dz-remove {
    opacity: 100;
    width: 85%;
  }
  #services-modal #dataTable thead tr{

background-color:#464646;
color:white;

  }
 #services-modal #dataTable thead tr th:nth-child(2){

width:50%;
  }

   #services-modal #dataTable thead tr th:nth-child(3){

width:21%;

  }

   #services-modal #dataTable thead tr th:nth-child(4){

width:1%;
 
  }
  #services-modal #dataTable thead tr th:nth-child(5){

width:1%;
 
  }
  #service-th th{
      background-color: #464646;
      color:white;
  }

#date-picker-div {

    display: flex;
    justify-content: flex-end;
    margin: 0px 0px 10px 0px;
    width:100% !important;
}

#date-picker-div  #date-picker {
  margin-right: 15px;
  }

#date-picker-div  span {
    margin-right: 10px;
    font-weight: bold;
  
  }

#date-picker-div  #date-picker input{
  
  font-size: 15.5px;

}

#date-picker-div  #date-picker button{

    font-size: 12.5px;

}

</style>
